import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const Contacts = () => {

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>Контакты</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <p>Мы всегда готовы помочь вам с использованием нашего сервиса онлайн поиска и оплаты штрафов ГИБДД. Если у вас возникли какие-либо вопросы, пожалуйста, свяжитесь с нами.</p>
                            <hr />
                            <p>ООО "ДНК МОБАЙЛ"</p>
                            <p>ИНН: 5402017733</p>
                            <p>ОГРН: 1165476089261</p>
                            <p>630001, Россия, г.Новосибирск, ул.Каменская 32, офис 607а</p>
                            <hr />
                            <p>По вопросам проверки и оплаты штрафов ГИБДД обращайтесь: 
                            <a href="mailto:info@shtrafy.info"> info@shtrafy.info</a></p>
                            <hr />
                            <p>По вопросам сотрудничества обращайтесь:
                            <a href="mailto:info@shtrafy.info"> info@shtrafy.info</a></p>
                            <hr />
                            <p>Мы всегда стараемся предоставить нашим пользователям лучший сервис и качественную поддержку.</p>
                            <p>Если у вас есть какие-либо пожелания или предложения по улучшению нашего сервиса, мы будем рады услышать их от вас. Свяжитесь с нами, и мы постараемся учесть ваши пожелания в нашей работе.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Contacts;
