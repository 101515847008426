import React, { useState } from 'react';
import { Button, Form, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from './Images/shtrafy_logo.svg';
import { NavLink } from "react-router-dom";

export default function NaviBar() {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    const handleShow = () => setShow(true);

    return (
    <>


        <Navbar collapseOnSelect expand='lg' variant='light'>
        <Container>
            <Navbar.Brand>
            <a href="/"><img src={logo} width="" height="50" className="d-inline-block align-top navbar-brand-logo" alt="Штрафы.Инфо логотип"></img></a>
            </Navbar.Brand>
            
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                
                </Nav>
                
                <Nav>
                    {/* <a href="/contacts" className="customlink">Контакты</a> */}
                    
                    <NavLink className="customlinkHead" to="/contacts">Контакты</NavLink>
                    <NavLink className="customlinkHead" to="/faq">Вопросы и ответы</NavLink>
                </Nav>
            </Navbar.Collapse>
        </Container>
        </Navbar>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Lof in</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlID="formBasicEmail">
                        <Form.Label>Email Adress</Form.Label>
                        <Form.Control type="email" placeholder="Enter Email" />
                        <Form.Text className="text-muted">We'll never share your email...</Form.Text>
                    </Form.Group>
                    <Form.Group controlID="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Enter Password" />

                    </Form.Group>
                    <Form.Group controlID="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />

                    </Form.Group>
                </Form>
            </Modal.Body>
        </Modal>
    </>
)}

//<Nav.Link><Link to="/">Home</Link></Nav.Link>
//<Nav.Link><Link to="/users">Users</Link></Nav.Link>
//<Nav.Link><Link to="/about">About</Link></Nav.Link>