import React from "react";

export const Stserrors = () => {

    return (
        <>
          <div className="suggest-tips">10 знаков в формате 00АА111222 или 0000111222</div>  
        </>
    )
}
export default Stserrors