import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import Guarantee from '../Images/Guarantee.svg';
import Fast from '../Images/Fast.svg';
import Secure from '../Images/Secure.svg';
import Kvitance from '../Images/Kvitance.svg';
import Vectors from '../Images/vectors.svg';


export const Finespromo = () => {
    return (
        <>
            <Container fluid="true">
                <Row >
                    
                    <Col className='colTipsfineshead'>
                        <div className='tipsfineshead'>
                        <span class="highlight-container"><span class="highlight">Гарантия оплаты штрафов</span></span></div>
                        <div className='tipstxt'>При оплате средства автоматически перечисляются на счет казначейства. Если ваш штраф не погасился после оплаты на нашем сайте - сделаем возврат денежных средств</div>
                    </Col>
                    <Col className='tipsfineslogo1 d-none d-xl-block'>
                        <img
                            className='finespromotips'
                            src={Guarantee}
                            alt="Фото нарушений"
                        />
                    </Col>
                </Row>
            </Container>

            <Container fluid="true">
                <Row>
                    
                <Col className='colTipsfineshead'>
                        <div className='tipsfineshead'>
                        <span class="highlight-container"><span class="highlight">Самая актуальная информация о штрафах</span></span></div>
                        <div className='tipstxt'>Мы постоянно обновляем информацию о ваших штрафах, чтобы вы смогли оплатить штраф с 50% скидкой</div>
                    </Col>
                    <Col className='tipsfineslogo2 d-none d-xl-block' >

                        <img
                            className='finespromotips'
                            src={Fast}
                            alt="Фото нарушений"
                        />
                    </Col>
                </Row>
            </Container>

            <Container fluid="true">
                <Row>
                <Col className='colTipsfineshead'>
                        <div className='tipsfineshead'>
                        <span class="highlight-container"><span class="highlight">Безопасная оплата</span></span></div>
                        <div className='tipstxt'>Платежи защищены по стандарту PCI DSS. Мы не храним данные ваших банковских карт</div>
                    </Col>
                    <Col className='tipsfineslogo3 d-none d-xl-block'>
                        <img
                            className='finespromotips'
                            src={Secure}
                            alt="Фото нарушений"
                        />
                    </Col>
                </Row>
            </Container>

            <Container fluid="true">
                <Row>
                    
                <Col className='colTipsfineshead'>
                        <div className='tipsfineshead'>
                        <span class="highlight-container"><span class="highlight">Квитанция после оплаты</span></span></div>
                        <div className='tipstxt'>После оплаты мы высылаем квитанцию подтверждающую факт оплаты штрафа</div>
                    </Col>
                    <Col className='tipsfineslogo4 d-none d-xl-block'>
                        <img
                            className='finespromotips'
                            src={Kvitance}
                            alt="Фото нарушений"
                        />
                    </Col>
                </Row>
            </Container>

        </>
    )
}
export default Finespromo
