import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import axios from "axios";

const API_TRANSFERMONEY = 'https://api.shtrafy.info/api/classes/paySendReq.php';
const API_PAYGETSUMM = 'https://api.shtrafy.info/api/classes/payGetSumm.php'

// const API_PAYGETSUMM = 'http://localhost:8888/api/classes/payGetSumm.php'
// const API_TRANSFERMONEY = 'http://localhost:8888/api/classes/paySendReq.php';

export const Success = () => {
    const [payData, setPayData] = React.useState([]);

    let mntidParams = (new URLSearchParams(window.location.search)).get("MNT_ID");
    let mntTransactionIdParams = (new URLSearchParams(window.location.search)).get("MNT_TRANSACTION_ID")
    let mntOperationIdParams = (new URLSearchParams(window.location.search)).get("MNT_OPERATION_ID")

    function sendYMSuccess(mntTransactionIdParams, comission, ymuin) {
        window.ym('93047780', "reachGoal", 'pay_success')
        window.dataLayer.push({
            ecommerce: {
                currencyCode: 'RUB',
                purchase: {
                    "actionField": {
                        "id": mntTransactionIdParams
                    },

                    products: [
                        {
                            id: mntTransactionIdParams,
                            price: comission,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    }

    React.useEffect(() => {
        fetch(API_TRANSFERMONEY, {
            method: "POST",
            body: JSON.stringify({ mntidParams: mntidParams, fineUin: mntTransactionIdParams, parentOperationId: mntOperationIdParams })
        })
            .then(() => {
                const payGetSumm = async () => {
                    try {
                        const resPayData = await axios.get(API_PAYGETSUMM + '?MNT_TRANSACTION_ID=' + mntTransactionIdParams)
                        const arr = []
                        Object.keys(resPayData.data).forEach(key => arr.push(resPayData.data[key]))
                        setPayData(arr);

                        sendYMSuccess(mntTransactionIdParams, arr[2])

                    } catch (err) {
                        console.error('error: ', err);
                    }
                }
                payGetSumm();

            })
            .catch((error) => console.log(error));
    }, []);


    //console.log('PayData: ', payData);



    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>Оплата успешно выполнена</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <h4>Ваш платеж отправлен в казначейство</h4>
                            <hr />
                            {/* <p>{mntidParams}</p>
                            <p>{mntTransactionIdParams}</p>
                            <p>{mntOperationIdParams}</p> */}
                            <p></p>
                            <p>Оплата штрафа по постановлению <strong> {mntTransactionIdParams} </strong> в размере <strong> {payData[0]} руб </strong> прошла успешно!</p>
                            <p><strong>В ближайшее время штраф будет погашен.</strong></p>
                            <p>Срок зачисления платежа составляет от нескольких минут до нескольких часов, по истечению этого времени, штраф перестанет отображаться во всех официальных источниках.</p>
                            <p>Квитанция об успешной оплате будет выслана на указанный вами адрес электронной почты: <strong> {payData[1]} </strong> после проведения платежа, обычно это занимает около 10 минут.</p>
                            <p>Данная квитанция является документом подтверждающим факт оплаты.</p>
                            <p>Если у вас возникли какие-либо вопросы, пожалуйста, свяжитесь с нашей службой поддержки. Мы будем рады помочь вам и ответить на все ваши вопросы.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )

}
export default Success;