import React from "react";
//import camera from './Components/Camera.svg';
import camera from '../Images/shtrafyinfoindex.svg';

export const Defaulttips = () => {

    return (
        <>
            <div className='righttips '>
                <h5>Проверьте ваши Штрафы ГИБДД</h5>
            </div>
            <div className='righttips '>
                <img
                    className='grs-img '
                    src={camera}
                    alt="Камера видео-фиксации нарушений"
                />
            </div>
            <div className='righttips '>
                <p></p>
            </div>
            <div className='righttips '>
                <p>Найдем все штрафы по данным автомобиля за 1 минуту</p>
            </div>
            <div className='righttips '>
                <p>Покажем место нарушения и фото с камер</p>
            </div>
        </>
    )

}
export default Defaulttips