import React from "react";
import grs from '../Images/grnn.svg';

export const Nomertips = () => {

    return (
        <>
            <div className='righttips'>
                <div>
                    <h5 >Государственный регистрационный номер вашего автомобиля</h5>
                    <img
                        className='grs-img'
                        src={grs}
                        alt="Госномер авто"
                    />
                </div>
            </div>
            <div className='righttips '>
                <p>Также номер авто можно посмотреть в свидетельстве о регистрации (СТС)</p>
            </div>


        </>
    )

}
export default Nomertips