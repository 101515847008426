import React from "react";
import { Link } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap";

export const NotFound = () => {
    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '0rem', }}>

            <Container fluid="true">
                <Row>
                    <Col>
                        <Container>
                            <div>
                                <h1>404</h1>
                                <p>Этой страницы не существует 404 :( </p>
                                <p>Мы не знаем как вы сюда попали, но вам лучше вернуться <Link to="/">домой</Link>
                                </p>
                            </div>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default NotFound;