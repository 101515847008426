import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const Security = () => {

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>Безопасность</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <h4>Безопасность – наш главный приоритет</h4>
                            <hr />
                            <p>Мы понимаем, что безопасность при оплате онлайн – это один из самых важных вопросов для наших пользователей. Мы сделали все возможное, чтобы защитить вас от любых возможных угроз и обеспечить безопасную и защищенную платежную среду.</p>
                            <p>При использовании сервиса Штрафы.Инфо для оплаты штрафов ГИБДД банковской картой, вы можете быть уверены в безопасности своих финансовых данных. Мы не храним информацию о вашей банковской карте на нашем сайте, а все платежи обрабатываются платежной системой Монета.Ру.</p>
                            <p>Мы применяем передовые технологии защиты данных и обеспечиваем полную конфиденциальность и безопасность при проведении транзакций. Вы можете смело использовать наш сервис для оплаты штрафов ГИБДД, зная, что ваши финансовые данные находятся под надежной защитой.</p>
                            <p>Если у вас возникли какие-либо вопросы или сомнения относительно безопасности нашего сервиса, пожалуйста, свяжитесь с нашей службой поддержки. Мы будем рады помочь вам и ответить на все ваши вопросы.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Security;
