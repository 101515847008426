import React from "react";

export const Nomererrors = () => {

    return (
        <>
          <div className="suggest-tips">Заполните госномер в формате A000AA</div>  
        </>
    )
}
export default Nomererrors