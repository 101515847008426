import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Table from 'react-bootstrap/Table';

export const Oferta = () => {

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>Лицензионное соглашение</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <>
                                <div>
                                    <div style={{ textalign: "center" }}>«04» апреля 2023 г.</div>
                                    <br></br>
                                    <span>
                                        Перед использованием Сервиса ознакомьтесь, пожалуйста, с условиями
                                        настоящего Лицензионного соглашения (далее – «Лицензия»). Если Вы не
                                        принимаете условия Лицензии в полном объёме, Вы не имеете права
                                        использовать Сервис.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ.</span>
                                    <br></br>
                                    <span>
                                        1.1. Лицензиар – Общество с ограниченной ответственностью «ДНК МОБАЙЛ»
                                        (ОГРН 1165476089261), обладающее исключительным правом на Сервис.
                                    </span>
                                    <br></br>
                                    <span>
                                        1.2. Лицензиат – физическое лицо, использующее Сервис на условиях
                                        настоящей Лицензии.
                                    </span>
                                    <br></br>
                                    <span>1.3. Сервис – программный комплекс Лицензиара, который:</span>
                                    <br></br>
                                    <span>
                                        · состоит из Приложений и Веб-сайта, имеющих единую
                                        программно-аппаратную часть, технически связанную с Системой
                                        Технического партнера и Партнерскими сервисами, и
                                    </span>
                                    <br></br>
                                    <span>
                                        · предназначен для реализации Функционала Сервиса путем
                                        доступа к интернет-сайту: shtrafy.info через сеть «Интернет»
                                        («Software as a service»).
                                    </span>
                                    <br></br>
                                    <span>
                                        1.4. Веб-сайт - “ШТРАФЫ.ИНФО”, доступ к которому
                                        обеспечивается посредством информационно-телекоммуникационной сети
                                        Интернет по доменному имени shtrafy.info
                                    </span>
                                    <br></br>
                                    <span>
                                        1.5. Функционал Сервиса – следующие функционально-технические
                                        возможности Сервиса, к которым Лицензиату предоставляется доступ через
                                        информационно-телекоммуникационную сеть «Интернет» посредством
                                        программного комплекса, составляющего Сервис:
                                    </span>
                                    <br></br>
                                    <span>
                                        · получение сведений о Задолженностях от Получателя платежа;
                                    </span>
                                    <br></br>
                                    <span>
                                        · получение реквизитов для оплаты таких Задолженностей от
                                        Получателя платежа;
                                    </span>
                                    <br></br>
                                    <span>
                                        · инициирование онлайн-запросов на проведение платежей в
                                        оплату данных Задолженностей с использованием Системы Технического
                                        партнера по полученным реквизитам;
                                    </span>
                                    <br></br>
                                    <span>
                                        · получение информации от Лицензиата для формирования
                                        целостных электронных платежных документов и осуществление последующей
                                        ее передачи Техническому партнеру;
                                    </span>
                                    <br></br>
                                    <span>
                                        · получение ответов Технического партнера о проведении или об
                                        отказе в проведении платежей в оплату Задолженностей;
                                    </span>
                                    <br></br>
                                    <span>
                                        · получение информации о получении Техническим партнером
                                        информации об уплате Лицензиатом Задолженностей с целью ее направления
                                        Получателю платежа;
                                    </span>
                                    <br></br>
                                    <span>
                                        · использование Партнерских сервисов на условиях и в порядке,
                                        установленных администраторами Партнерских сервисов;
                                    </span>
                                    <br></br>
                                    <span>
                                        · взаимодействие c Лицензиаром по вопросам функционирования
                                        Сервиса в рамках настоящей Лицензии, в том числе по вопросам качества
                                        Сервиса.
                                    </span>
                                    <br></br>
                                    <span>
                                        1.6. Система – техническая инфраструктура Технического партнера,
                                        обеспечивающая, на основании онлайн-запросов, инициированных
                                        Лицензиатами в Сервисе, обработку информации от Лицензиатов с целью
                                        осуществления оплаты Задолженностей.
                                    </span>
                                    <br></br>
                                    <span>
                                        1.7. Технический партнер – организация, администрирующая Систему и в
                                        соответствии с законодательством обязанная направлять информацию об
                                        оплате Задолженностей в Государственную информационную систему о
                                        государственных и муниципальных платежах (ГИС ГМП) для ее получения
                                        Получателем платежа.
                                    </span>
                                    <br></br>
                                    <span>
                                        Техническими партнерами Лицензиара являются Небанковская кредитная
                                        организация «МОНЕТА» (общество с ограниченной ответственностью) (ОГРН
                                        1121200000316, лицензия Банка России № № 3508-К). направляющий
                                        информацию об оплате Задолженностей в «ГИС ГМП»
                                    </span>
                                    <br></br>
                                    <span>
                                        1.8. Штраф – административное наказание в виде денежного штрафа за
                                        нарушение ПДД, назначаемый как физическим, так и юридическим лицам.
                                    </span>
                                    <br></br>
                                    <span>
                                        1.9. ПДД – правила дорожного движения, утвержденные Постановлением
                                        Правительства РФ от 23 октября 1993 г. № 1090 «О правилах дорожного
                                        движения».
                                    </span>
                                    <br></br>
                                    <span>
                                        1.10. Пеня – установленная законодательством РФ денежная сумма,
                                        которую налогоплательщик должен выплатить в случае уплаты
                                        причитающихся сумм Налогов в более поздние по сравнению с
                                        установленными законодательством о налогах и сборах сроки.
                                    </span>
                                    <br></br>
                                    <span>
                                        1.11. ИП ФССП – задолженность по исполнительным производствам,
                                        возбужденным в порядке, предусмотренном Федеральным законом от
                                        02.10.2007 N 229-ФЗ «Об исполнительном производстве».
                                    </span>
                                    <br></br>
                                    <span>
                                        1.12. УИН – уникальный идентификатор начисления (для Штрафов, ИП
                                        ФССП).
                                    </span>
                                    <br></br>
                                    <span>1.13. Задолженности – Штраф, Пеня, ИП ФССП.</span>
                                    <br></br>
                                    <span>
                                        1.14. Получатель платежа - органы государственной власти и органы
                                        местного самоуправления, учреждения, находящиеся в их ведении,
                                        получающие денежные средства Лицензиата в рамках выполнения ими
                                        функций, установленных законодательством Российской Федерации.
                                    </span>
                                    <br></br>
                                    <span>
                                        1.15. Информация от Лицензиата – предоставляемая Лицензиатом любым
                                        возможным в рамках Сервиса способом информация (в том числе на
                                        электронную почту Лицензиара, указанную в разделе 9 настоящей
                                        Лицензии), указанная в разделе 5 настоящей Лицензии, на обработку
                                        которой Лицензиатом дается согласие.
                                    </span>
                                    <br></br>
                                    <span>
                                        1.16. Материалы Сервиса – все текстовые, графические и иные
                                        размещенные на Сервисе материалы.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>2. ЗАКЛЮЧЕНИЕ ДОГОВОРА</span>
                                    <br></br>
                                    <span>
                                        2.1. Использование Вами Сервиса любым способом в пределах его
                                        функциональных возможностей, составляющих Функционал Сервиса, означает
                                        полное и безусловное согласие на заключение договора на условиях
                                        настоящей Лицензии в соответствии со ст. 434, 437, 438, 1286 ГК РФ.
                                    </span>
                                    <br></br>
                                    <span>
                                        2.2. Использование Сервиса разрешается только на условиях настоящей
                                        Лицензии. Если Вы не принимаете условия Лицензии в полном объёме, Вы
                                        не имеете права использовать Сервис. Использование Сервиса с
                                        нарушением (невыполнением) какого-либо из условий Лицензии запрещено.
                                    </span>
                                    <br></br>
                                    <span>
                                        2.3. Заключая договор на условиях настоящей Лицензии, как это
                                        определено в п.2.1, Вы подтверждаете, что:
                                    </span>
                                    <br></br>
                                    <span>
                                        · Ознакомились с условиями настоящей Лицензии в полном объеме
                                        до начала использования Сервиса;
                                    </span>
                                    <br></br>
                                    <span>
                                        · Принимаете все условия настоящей Лицензии в полном объеме
                                        без каких-либо изъятий и ограничений с вашей стороны и обязуетесь их
                                        соблюдать или незамедлительно прекратить использование Сервиса;
                                    </span>
                                    <br></br>
                                    <span>
                                        · Настоящая Лицензия (в том числе любая из ее частей) может
                                        быть изменена Лицензиаром и опубликована в новой редакции без
                                        какого-либо предварительного специального уведомления. Новая редакция
                                        Лицензии вступает в силу с момента ее опубликования в Сервисе, если
                                        иное не предусмотрено новой редакцией Лицензии.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>3. ПРЕДМЕТ ЛИЦЕНЗИИ</span>
                                    <br></br>
                                    <span>
                                        3.1. Предметом настоящей Лицензии является предоставление Лицензиаром
                                        Лицензиату права использования Сервиса посредством удаленного доступа
                                        к Сервису через информационно-телекоммуникационную сеть «Интернет» на
                                        условиях и в пределах, установленных настоящей Лицензией, с
                                        сохранением за Лицензиаром права выдачи лицензий иным лицам (простая
                                        (неисключительная) лицензия).
                                    </span>
                                    <br></br>
                                    <span>
                                        3.2. Размер лицензионного вознаграждения Лицензиара за предоставление
                                        Лицензиату права использования Сервиса в соответствии с п.2.1.
                                        Лицензии определяется в зависимости от суммы Задолженности в случае
                                        оплаты таковой Лицензиатом посредством Сервиса.
                                    </span>
                                    <br></br>
                                    <span>
                                        3.2.1. В случае оплаты Задолженности Лицензиатом посредством Сервиса
                                        размер лицензионного вознаграждения Лицензиара определяется по каждой
                                        сумме оплачиваемой Задолженности в соответствии с нижеуказанной
                                        таблицей:
                                        <>
                                        <Table striped bordered hover size="sm"> 
                                            <thead>
                                                <tr>
                                                    <th>Сумма Задолженности, оплачиваемой Лицензиатом</th>
                                                    <th>Размер лицензионного вознаграждения Лицензиара (НДС не облагается)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>0 – 499,99 руб.</td>
                                                    <td>49,00 руб.</td>
                                                </tr>
                                                <tr>
                                                    <td>500 – 799,99 руб.</td>
                                                    <td>74,00 руб.</td>
                                                </tr>
                                                <tr>
                                                    <td>800 – 2499,99 руб.</td>
                                                    <td>9% от суммы</td>
                                                </tr>

                                                <tr>
                                                    <td>2500 руб.</td>
                                                    <td>300 руб.</td>
                                                </tr>

                                                <tr>
                                                    <td>2500,01 и выше</td>
                                                    <td>8% от суммы</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        </>
                                    </span>
                                    <span>
                                        3.3. Действие настоящей Лицензии распространяется на все последующие
                                        обновления/новые версии Сервиса. Используя обновления/новые версии
                                        Сервиса так, как это определено в п.2.1 настоящей Лицензии, Лицензиат
                                        принимает условия настоящей Лицензии для соответствующих
                                        обновлений/новых версий Сервиса, если обновление/новая версия Сервиса
                                        не сопровождается иным лицензионным соглашением.
                                    </span>
                                    <br></br>
                                    <span>
                                        3.4. Лицензиар предоставляет Лицензиату право использования Сервиса на
                                        территории всего мира в порядке и на условиях, предусмотренных
                                        действующим законодательством Российской Федерации и настоящей
                                        Лицензией.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>4. ГАРАНТИИ СТОРОН</span>
                                    <br></br>
                                    <span>
                                        4.1. Лицензиар гарантирует, что обладает всеми необходимыми правами
                                        для предоставления их Лицензиату в порядке и на условиях,
                                        предусмотренной настоящей Лицензией.
                                    </span>
                                    <br></br>
                                    <span>
                                        4.2. Лицензиат заверяет Лицензиара о следующих обстоятельствах,
                                        имеющих значение для заключения договора на условиях настоящей
                                        Лицензии:
                                    </span>
                                    <br></br>
                                    <span>
                                        · Лицензиат обладает всеми необходимыми правами и полномочиями
                                        для заключения Лицензии на использование Сервиса;
                                    </span>
                                    <br></br>
                                    <span>
                                        · Использование Сервиса будет осуществляться Лицензиатом
                                        исключительно в пределах настоящей Лицензии и с соблюдением
                                        действующего законодательства Российской Федерации (в частности, при
                                        использовании Лицензиатом информации о третьих лицах).
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>5. ОБЩИЙ ПОРЯДОК ФУНКЦИОНИРОВАНИЯ И ИСПОЛЬЗОВАНИЯ СЕРВИСА</span>
                                    <br></br>
                                    <span>
                                        5.1. Лицензиар стремится обеспечить доступность Сервиса в
                                        круглосуточном режиме с возможными технологическими перерывами
                                        совокупной продолжительностью не более 1 (одного) часа в сутки, а
                                        также принимать меры по поступившим заявкам от Лицензиата об ошибках
                                        или иных проблемах, связанных с функционированием Сервиса.
                                        Технологические перерывы в доступности Сервиса чаще всего
                                        обуславливаются неработоспособностью информационных каналов,
                                        находящихся за пределами собственных ресурсов Лицензиара, а также
                                        необходимым профилактическим ремонтом и обслуживанием оборудования
                                        Лицензиара, в том числе по аварийным обстоятельствам. Обеспечение
                                        функционирования Сервиса, исправление ошибок и обновлений Сервиса
                                        является правом, но не обязанностью Лицензиара.
                                    </span>
                                    <br></br>
                                    <span>
                                        5.2. Лицензиар имеет право выпускать обновления Сервиса (в том числе
                                        версии Программ и Веб-сайта), временно или окончательно изменять
                                        Функционал Сервиса и (или) пользовательский интерфейс в любое время
                                        без предварительного согласования с Лицензиатом.
                                    </span>
                                    <br></br>
                                    <span>
                                        5.3. Лицензиар без взимания платы оказывает Лицензиату информационную
                                        поддержку по вопросам, связанным с Сервисом, путем любых доступных в
                                        рамках интерфейса Сервиса способов, в том числе взаимодействия по
                                        электронной почте, указанной в разделе 10 настоящей Лицензии.
                                    </span>
                                    <br></br>
                                    <span>
                                        5.4. Исключительные права на Материалы Сервиса принадлежат Лицензиару.
                                        Копирование Материалов Сервиса без письменного разрешения Лицензиара
                                        влечет ответственность в соответствии с законодательством Российской
                                        Федерации.
                                    </span>
                                    <br></br>
                                    <span>
                                        5.5. Время, используемое в Сервисе, соответствует текущему московскому
                                        часовому поясу.
                                    </span>
                                    <br></br>
                                    <span>
                                        5.6. Лицензиар оставляет за собой право приостановить технически
                                        использование Сервиса Лицензиатом в случае нарушения (или подозрения в
                                        нарушении) последним настоящей Лицензии или законодательства
                                        Российской Федерации.
                                    </span>
                                    <br></br>
                                    <span>
                                        5.7. Лицензиат имеет право осуществлять любые действия в рамках
                                        Функционала Сервиса, если такие действия Лицензиата не нарушают
                                        настоящую Лицензию, права третьих лиц и действующее законодательство
                                        (в частности, путем получения несанкционированного доступа к
                                        информации ограниченного доступа, ее распространение и пр.)
                                    </span>
                                    <br></br>
                                    <span>
                                        5.8. Лицензиат при использовании Сервиса обязуется не совершать
                                        действия, которые могут повлечь: а) нарушение функционирования
                                        оборудования и сети Лицензиара; б) нарушение работы Сервиса или
                                        ограничение возможностей других Лицензиатов в использовании Сервиса;
                                        в) несанкционированный доступ к Сервису, а также
                                        информационно-вычислительным и сетевым ресурсам Лицензиара; г)
                                        использование автоматических программных и технических средств
                                        («парсинг») в целях использования Функционала Сервиса (в частности,
                                        для получения Лицензиатом информации о Задолженностях с целью
                                        последующего предоставления такой информации на каких-либо иных
                                        платформах в сети «Интернет»).
                                    </span>
                                    <br></br>
                                    <span>
                                        5.9. Использование Сервиса Лицензиатом невозможно без доступа к сети
                                        «Интернет», в связи с чем Лицензиату требуется самостоятельно
                                        обеспечивать такой доступ для корректного использования Сервиса.
                                    </span>
                                    <br></br>
                                    <span>
                                        5.10. В случае, если информация об оплате Задолженностей посредством
                                        Сервиса не отразилась в ГИС ГМП, Лицензиат вправе обратиться к
                                        Лицензиару за помощью в установлении факта оплаты перед обращением в
                                        соответствующий государственный орган.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>6. ИНФОРМАЦИЯ ОТ ЛИЦЕНЗИАТА</span>
                                    <br></br>
                                    <span>
                                        6.1. Лицензиат, действуя свободно, своей волей и в своем интересе,
                                        подтверждая свою дееспособность, дает свое согласие Лицензиару на
                                        обработку своих персональных данных путем принятия настоящей Лицензии
                                        в предусмотренном порядке. Такое согласие дается на следующих
                                        условиях:
                                    </span>
                                    <br></br>
                                    <span>
                                        6.1.1. Согласие дается на обработку персональных данных, как без
                                        использования средств автоматизации, так и с их использованием.
                                    </span>
                                    <br></br>
                                    <span>
                                        6.1.2. Согласие дается на обработку следующих персональных данных:
                                    </span>
                                    <br></br>
                                    <span>
                                        Персональные данные, не являющиеся специальными или биометрическими:
                                    </span>
                                    <br></br>
                                    <span>фамилия, имя, отчество;</span>
                                    <br></br>
                                    <span>адреса электронной почты;</span>
                                    <br></br>
                                    <span>номер водительского удостоверения;</span>
                                    <br></br>
                                    <span>сведения о дате выдачи водительского удостоверения;</span>
                                    <br></br>
                                    <span>
                                        сведения об автомобиле (номер СТС; марка и модель автомобиля;
                                        государственный номер автомобиля; номер ПТС, VIN);
                                    </span>
                                    <br></br>
                                    <span>реквизиты банковского счета;</span>
                                    <br></br>
                                    <span>реквизиты банковской карты.</span>
                                    <br></br>
                                    <span>
                                        6.1.3. Указанные в п.6.1.2. настоящей Лицензии персональные данные не
                                        являются общедоступными.
                                    </span>
                                    <br></br>
                                    <span>
                                        6.1.4. Цель обработки персональных данных: создание профиля
                                        Лицензиата, обеспечивающего полноценное использование Лицензиатом
                                        Функционала Сервиса.
                                    </span>
                                    <br></br>
                                    <span>
                                        6.1.5. В ходе обработки с персональными данными будут совершены
                                        следующие действия: сбор; запись; систематизация; накопление;
                                        хранение; уточнение (обновление, изменение); извлечение;
                                        использование, в том числе в целях осуществления запросов в
                                        информационные системы, содержащие информацию о Задолженностях;
                                        передача (предоставление, в том числе указанным в п.6.1.6. третьим
                                        лицам, доступ); блокирование; удаление; уничтожение.
                                    </span>
                                    <br></br>
                                    <span>
                                        6.1.6. Следующие третьи лица обрабатывают предоставленные персональные
                                        данные по поручению Лицензиара:
                                    </span>
                                    <br></br>
                                    <span>
                                        НКО «МОНЕТА» (ООО) (город Йошкар-Ола, ул. Гоголя, д. 2, строение «А»)
                                    </span>
                                    <br></br>
                                    <span>
                                        6.2. Персональные данные обрабатываются до истечения трех лет после
                                        прекращения договорных отношений с Лицензиатом. Обработка любых
                                        персональных данных может быть прекращена по запросу Лицензиата.
                                        Хранение персональных данных, зафиксированных на бумажных носителях
                                        осуществляется согласно Федеральному закону №125-ФЗ «Об архивном деле
                                        в Российской Федерации» и иным нормативно правовым актам в области
                                        архивного дела и архивного хранения.
                                    </span>
                                    <br></br>
                                    <span>
                                        6.3. Согласие может быть отозвано Лицензиатом или его представителем
                                        путем направления письменного заявления Лицензиару по адресам,
                                        указанным в разделе 10 настоящей Лицензии.
                                    </span>
                                    <br></br>
                                    <span>
                                        6.4. В случае отзыва Лицензиатом или его представителем согласия на
                                        обработку персональных данных Лицензиар вправе продолжить обработку
                                        персональных данных без согласия Лицензиата при наличии оснований,
                                        указанных в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части
                                        2 статьи 11 Федерального закона №152-ФЗ «О персональных данных» от
                                        27.07.2006 г.
                                    </span>
                                    <br></br>
                                    <span>
                                        6.5. Настоящее согласие действует все время до момента прекращения
                                        обработки персональных данных, указанного в п.6.2. и п.6.3. настоящей
                                        Лицензии.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>7. ОГРАНИЧЕНИЯ</span>
                                    <br></br>
                                    <span>
                                        7.1. Сервис предоставляется на условиях «как есть» (as is). Лицензиар
                                        не предоставляет никаких гарантий в отношении безошибочной и
                                        бесперебойной работы Сервиса или отдельных его компонентов и/или
                                        Функционала Сервиса, соответствия Сервиса конкретным целям и ожиданиям
                                        Лицензиата, а также не предоставляет никаких иных гарантий, прямо не
                                        указанных в настоящей Лицензии.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.2. Лицензиар не несет ответственности перед Лицензиатом за задержки,
                                        перебои в работе и невозможность полноценного использования Сервиса,
                                        за исключением случаев, прямо указанных в настоящей Лицензии.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.3. Лицензиар не является лицом, уполномоченным на составление базы
                                        данных о неоплаченных Задолженностях, и по этой причине не несет
                                        ответственности за достоверность и (или) актуальность получаемых в
                                        автоматическом режиме сведений о наличии неоплаченных Задолженностей.
                                        В случае если Лицензиат считает, что информация о Задолженностях,
                                        полученная посредством Сервиса, не является достоверной и (или)
                                        актуальной, Лицензиату надлежит обратиться к Получателю платежа (в
                                        соответствующее территориальное Управление Госавтоинспекции при МВД
                                        РФ, отделение ФНС РФ или соответствующий отдел судебных приставов и
                                        т.п.) с целью уточнения информации о таких Задолженностях.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.4. Сервис осуществляет получение информации о неоплаченных
                                        Задолженностях с помощью доступных законных технических и
                                        информационных средств на основе соглашений с Техническими партнерами
                                        Сервиса, в связи с чем Лицензиар не несет ответственности перед
                                        Лицензиатом в случае задержки либо искажения в предоставленной
                                        информации сведений о Задолженностях, платежных реквизитах, возникших
                                        по независящим от Лицензиара причинам.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.5. В соответствии с п.4 ст. 21.3 Федерального закона от 27.07.2010 N
                                        210-ФЗ «Об организации предоставления государственных и муниципальных
                                        услуг», Технический партнер обязан незамедлительно при достаточности
                                        денежных средств для исполнения распоряжения о переводе денежных
                                        средств в пользу уплаты Задолженности, не позднее дня приема к
                                        исполнению соответствующего распоряжения, а при приеме к исполнению
                                        распоряжения о переводе денежных средств после 21 часа по местному
                                        времени - не позднее дня, следующего за днем приема к исполнению
                                        соответствующего распоряжения, направлять информацию об уплате
                                        Задолженностей в Государственную информационную систему о
                                        государственных и муниципальных платежах (ГИС ГМП). Лицензиар, таким
                                        образом, не является лицом, уполномоченным на совершение вышеуказанных
                                        функций, прямо закрепленных действующим законодательством за
                                        Техническим партнером.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.6. Лицензиар не несет ответственности за любые прямые или косвенные
                                        убытки, понесенные Лицензиатом в период и/или в связи с использованием
                                        или не использованием Сервиса, за исключением случаев, указанных в
                                        разделе 7 настоящей Лицензии. При любых обстоятельствах
                                        ответственность Лицензиара перед Лицензиатом ограничена суммой,
                                        состоящей из Задолженности, оплаченной посредством Сервиса, и
                                        уплаченного в связи с этим лицензионного вознаграждения.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.7. Лицензиар не несет ответственности за изменение свойств, функций
                                        и способов осуществления переводов в счет оплаты Задолженностей, если
                                        таковые явно не описаны в Лицензии.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.8. В случае использования в Сервисе информации о третьих лицах
                                        ответственность при отсутствии полномочий на использование такой
                                        информации (в том числе перед Лицензиаром) несет Лицензиат.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.9. В случае привлечения Правообладателя к ответственности или
                                        наложения на него взыскания в связи с использованием Лицензиатом
                                        Сервиса с нарушением действующего законодательства, нарушениями прав
                                        и/или интересов третьих лиц, а также вследствие недостоверности
                                        заверений, указанных в разделе 4 настоящей Лицензии, Лицензиат обязаны
                                        в полном объеме возместить убытки Лицензиара.
                                    </span>
                                    <br></br>
                                    <span>
                                        7.10. С условиями и порядком предоставления компенсации можно
                                        ознакомиться по ссылке.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>8. ПРИМЕНИМОЕ ЗАКОНОДАТЕЛЬСТВО И ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ</span>
                                    <br></br>
                                    <span>
                                        8.1. Настоящая Лицензия регулируется российским законодательством, в
                                        том числе в части обработки персональных данных. Вопросы, не
                                        урегулированные настоящей Лицензией, разрешаются в соответствии с
                                        законодательством Российской Федерации.
                                    </span>
                                    <br></br>
                                    <span>
                                        8.2. Если какое-либо условие настоящей Лицензии будет признано судом
                                        или любым другим компетентным органом недействительным, незаконным или
                                        не имеющим силу, то это не повлияет на действительность, законность и
                                        действие остальных условий настоящей Лицензии.
                                    </span>
                                    <br></br>
                                    <span>
                                        8.3. Все споры, возникающие в связи с использованием Сервиса,
                                        разрешаются Лицензиаром и Лицензиатом путем переговоров. При
                                        невозможности прийти к согласию споры между Лицензиаром и Лицензиатом
                                        подлежат рассмотрению в соответствии с законодательством Российской
                                        Федерации.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</span>
                                    <br></br>
                                    <span>
                                        9.1. Вы соглашаетесь получать от Лицензиара информационные сообщения
                                        (далее — «нотификаторы») о важных событиях, происходящих в рамках
                                        Сервиса или в связи с ним, на указанный при использовании Сервиса
                                        адрес электронной почты (нотификатор в виде писем)
                                    </span>
                                    <br></br>
                                    <span>
                                        9.1.1. Лицензиар вправе использовать нотификаторы в целях
                                        информирования Лицензиата о проведении маркетинговых акций, а также в
                                        целях рекламного характера.
                                    </span>
                                    <br></br>
                                    <span>
                                        9.1.2. Согласие Лицензиата на получение от Лицензиара таких
                                        нотификаций может быть отозвано путем использования соответствующего
                                        функционала Сервиса; путем направления обращения в службу поддержки по
                                        электронной почте, указанной в разделе 10 настоящей Лицензии; путем
                                        направления письменного обращения в адрес Лицензиара, указанный в
                                        разделе 10 настоящей Лицензии.
                                    </span>
                                    <br></br>
                                    <span>
                                        9.2. Лицензиар вправе проводить акции, предусматривающие уменьшение
                                        Лицензионного вознаграждения, предусмотренного п. 3.2.1 настоящей
                                        Лицензии. Информация о наличии действующих акций, а также о сроках,
                                        порядке и условиях проведения таких акций указываются в информационных
                                        материалах сервиса.
                                    </span>
                                    <br></br>
                                    <span>
                                        9.3. Лицензиар оставляет за собой право в любое время изменить
                                        настоящую Лицензию (любую из ее частей) и опубликовать новую редакцию
                                        Лицензии без предварительного специального уведомления. Новая редакция
                                        Лицензии вступает в силу с момента ее опубликования в Сервисе, если
                                        иное не предусмотрено новой редакцией Сервиса.
                                    </span>
                                    <br></br>
                                    <span>
                                        9.4. Лицензиат обязуется регулярно обращаться к соответствующему
                                        разделу Сервиса с целью ознакомлением с действующей редакцией
                                        Лицензии. При несогласии с действующей редакцией Лицензии Лицензиат
                                        обязуется немедленно прекратить использование Сервиса.
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>10. КОНТАКТЫ И РЕКВИЗИТЫ ЛИЦЕНЗИАРА</span>
                                    <br></br>
                                    <span>10.1. Адреса электронной почты для связи с Лицензиаром:</span>
                                    <br></br>
                                    <span>
                                        Адрес электронной почты для связи со специалистами службы поддержки
                                        (технические вопросы) и по вопросам, связанным с персональными
                                        данными: info@shtrafy.info
                                    </span>
                                    <br></br>
                                    <span>
                                        Адрес электронной почты для направления претензий, обращений, запросов
                                        государственных органов: info@shtrafy.info
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span>10.2. Реквизиты Лицензиара:</span>
                                    <br></br>
                                    <span>
                                        ООО «ДНК МОБАЙЛ»
                                    </span>
                                    <br></br>
                                    <span>ОГРН 1165476089261</span>
                                    <br></br>
                                    <span>ИНН/КПП 1655211935 / 540601001</span>
                                    <br></br>
                                    <span>Адрес: 630001, Россия, г.Новосибирск, ул.Каменская 32, офис 607а</span>
                                </div>
                            </>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default Oferta;