import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const Payurl = () => {
    
    let mntidParams = (new URLSearchParams(window.location.search)).get("MNT_ID");
    let mntTransactionIdParams = (new URLSearchParams(window.location.search)).get("MNT_TRANSACTION_ID")
    let mntOperationIdParams = (new URLSearchParams(window.location.search)).get("MNT_OPERATION_ID")

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>Оплата успешно выполнена</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <h4>Ваш платеж отправлен отправлен в казначейство</h4>
                            <hr />
                            <p>{mntidParams}</p>
                            <p>{mntTransactionIdParams}</p>
                            <p>{mntOperationIdParams}</p>
                            
                            <p>Срок зачисления платежа составляет от нескольких минут до нескольких часов, по истечению этого времени, штраф перестанет отображаться во всех официальных источниках.</p>
                            <p>Квитанция об успешной оплате выслана на указанный вами адрес электронной почты. Данная квитанция является документом подтверждающим факт оплаты.</p>
                            <p>Если у вас возникли какие-либо вопросы, пожалуйста, свяжитесь с нашей службой поддержки. Мы будем рады помочь вам и ответить на все ваши вопросы.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Payurl;