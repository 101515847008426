import React from "react";
import { Container, Row, Col, Card, Button, InputGroup, Form } from "react-bootstrap";
import camera from './Components/Images/Camera.svg';
import logo from './Components/Images/logo.svg';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from "axios";
import MD5 from "crypto-js/md5";
import MaskedInput from 'react-text-mask';
import Ststips from "./Components/Indexblocks/Ststips";
import Defaulttips from "./Components/Indexblocks/Defaulttips";
import Nomertips from "./Components/Indexblocks/Nomertips";
import Emailtips from "./Components/Indexblocks/Emailtips";
import Regiontips from "./Components/Indexblocks/Regiontips";

import Noerrors from "./Components/Indexblocks/Noerrors";
import Stserrors from "./Components/Indexblocks/Stserrors";
import Nomererrors from "./Components/Indexblocks/Nomererrors";
import Regionerrors from "./Components/Indexblocks/Regionerrors";
import Emailerrors from "./Components/Indexblocks/Emailerrors";

import Firstblock from "./Components/Indexblocks/Firstblock";
import Secondblock from "./Components/Indexblocks/Secondblock";
import Thirdblock from "./Components/Indexblocks/Thirdblock";
import Fourthblock from "./Components/Indexblocks/Fourthblock";
import Fifthblock from "./Components/Indexblocks/Fifthblock";

import createNumberMask from 'text-mask-addons/dist/createNumberMask'


const API_PATH = 'https://api.shtrafy.info/api/getid.php';
const API_SESSIONS = 'https://api.shtrafy.info/api/sessions.php';
const API_STSBYNOM = 'https://api.shtrafy.info/api/classes/getsts.php';

// const API_PATH = 'http://localhost:8888/api/getid.php';
// const API_SESSIONS = 'http://localhost:8888/api/sessions.php';
// const API_STSBYNOM = 'http://localhost:8888/api/classes/getsts.php';

const HomeGN = () => {
    var hash = require('hash.js')
    const cookies = new Cookies();
    const navigate = useNavigate();
    const [state, setState] = React.useState([{ sts: '', nomer: '', vu: '', email: '', nom: '', reg: '' }]);
    const [emailfield, setEmail] = React.useState("");
    const [nomerfield, setNomerfield] = React.useState("");
    const [stsfield, setStsfield] = React.useState("");
    const [vufield, setVufield] = React.useState("");
    const [regionfield, setRegionfield] = React.useState("");
    const [usertoken, setUsertoken] = React.useState("");
    const [consenstoken, setConsenstoken] = React.useState("");
    const [activeField, setActiveField] = React.useState('default');
    const [showSts, setShowSts] = React.useState(false);

    //const firstLetter = /(?!.*[12])[A-VXY]/i;
    const firstLetter = /[аАвВеЕкКмМнНоОрРсСтТуУхХaAbBeEkKmMhHoOpPcCtTyYxX]/;
    //const letter = /(?!.*[DFIOQU])[A-Z]/i;
    const letter = /[аАвВеЕкКмМнНоОрРсСтТуУхХaAbBeEkKmMhHoOpPcCtTyYxX]/;
    const digit = /[0-9]/;
    const mask = [firstLetter, digit, digit, digit, letter, letter];
    const fullLetterChar = /[А-Яа-яЁёA-Za-z0-9]/;

    const numberMask = createNumberMask({
        prefix: '',
        suffix: '',
        allowLeadingZeroes: true,
        integerLimit: 3
    })

    const a = { "А": "A", "В": "B", "Е": "E", "К": "K", "М": "M", "Н": "H", "О": "O", "Р": "P", "С": "C", "Т": "T", "У": "Y", "Х": "X", "а": "A", "в": "B", "е": "E", "к": "K", "м": "M", "н": "H", "о": "O", "р": "P", "с": "C", "т": "T", "у": "Y", "х": "X" };

    function transliterate(word) {
        return word.split('').map(function (char) {
            return a[char] || char;
        }).join("");
    }

    const hints = {
        default: <Defaulttips />,
        sts: <Ststips />,
        nomer: <Nomertips />,
        region: <Regiontips />,
        email: <Emailtips />
    };

    const errors = {
        default: <Noerrors />,
        sts: <Stserrors />,
        nomer: <Nomererrors />,
        region: <Regionerrors />,
        email: <Emailerrors />
    };

    const preToken = cookies.get('token')

    React.useEffect(() => {

        if (preToken !== undefined) {
            //console.log('preToken is :', preToken)
            const sendGetRequest = async () => {
                try {
                    const res = await axios.get(API_SESSIONS + '?token=' + preToken)
                    const arr = []
                    Object.keys(res.data).forEach(key => arr.push(res.data[key]))
                    setState(arr);

                    // console.log('res.data: ', res.data[0]);
                    // console.log('res.data.sts: ', res.data[0].sts);  
                    // console.log('res.data.vu: ', res.data[0].vu);  

                    setStsfield(res.data[0].sts)
                    setEmail(res.data[0].email)
                    setVufield(res.data[0].vu)
                    setRegionfield(res.data[0].reg)
                    setNomerfield(res.data[0].nom)

                } catch (err) {
                    console.error('error: ', err);
                }
                //console.log('setStsfield: ', stsfield);
            };
            sendGetRequest();
        }

    }, []);


    const getStsByNom = async () => {
        try {
            //console.log('nom is: ', nomerfield);
            //console.log('reg is: ', regionfield);
            if (nomerfield !== '' && regionfield !== '' && regionfield?.length != 0 && regionfield?.length >= 2 && nomerfield?.length != 0 && nomerfield?.length > 5) {
                //console.log('nomer yes: ');
                const fullnom = (nomerfield + regionfield).toString();
                const responsests = await axios.get(API_STSBYNOM + '?grz=' + fullnom)
                const arr2 = []

                Object.keys(responsests.data).forEach(key => arr2.push(responsests.data[key]))

                if (typeof responsests.data['response']['result'][0] !== "undefined") {
                    //console.log('_sts series is_: ', responsests.data['response']['result'][0]);

                    const stsseries = responsests.data['response']['result'][0]['series'];
                    const stsnumber = responsests.data['response']['result'][0]['number'];
                    const fullstsnumber = stsseries + stsnumber;
                    //console.log('fullstsnumber is: ', fullstsnumber);

                    setStsfield(fullstsnumber);

                } else {
                    //здесь сценарий если не получили СТС по номеру
                    console.log('undefined: ', responsests.data['response']['result'][0]);
                    setShowSts(true);
                }

            } else {
                //console.log('nomer no: ');
            }

        } catch (err) {
            console.error('error2 : ', err);
        }
    }


    function handleSubmit(e) {
        const nomer = (nomerfield + regionfield).toString();
        //console.log('nomer: ', nomer);

        const forT = (emailfield + nomer).toString();
        //console.log('forT: ', forT);

        const consensT = (stsfield).toString();
        //console.log('consensT: ', consensT);



        //console.log(MD5(emailfield + nomer).toString());
        //const token = MD5(emailfield).toString();
        //console.log('token: ', token);

        const tokenForT = MD5(forT).toString();
        const tokenConsens = MD5(consensT).toString();


        //console.log('tokenConsens: ', tokenConsens);
        //console.log('tokenForT: ', tokenForT);
        setUsertoken(tokenForT);
        setConsenstoken(tokenConsens);

        const newNomer = transliterate(nomer);
        const newSts = transliterate(stsfield);
        //console.log('usertoken: ', usertoken);

        //sessionStorage.setItem('token', JSON.stringify(emailfield));
        cookies.set('token', tokenForT, { path: '/' });
        //cookies.set('nomer', nomerfield, { path: '/' })
        //cookies.set('reg', regionfield, { path: '/' })
        // cookies.set('sts', stsfield, { path: '/' })
        // cookies.set('vu', vufield, { path: '/' })

        //console.log('consenstoken: ', consenstoken);

        e.preventDefault();
        fetch(API_PATH, {
            method: "POST",
            body: JSON.stringify({ emailfield: emailfield, nomer: newNomer, token: tokenForT, stsfield: newSts, vufield: vufield, nomerfield: nomerfield, regionfield: regionfield, consenstoken: tokenConsens })
        })
            .then(() => {
                //console.log('post data sent, redirect...');
                navigate('/fines');
            })
            .catch((error) => console.log(error));
    }

    return (

        <Container style={{ paddingTop: '1rem', paddingBottom: '1rem', }}>
            <h1>Поиск и оплата штрафов по номеру автомобиля</h1>
            <Row style={{ paddingTop: '1rem', paddingBottom: '1rem', }} className='row-custom'>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <div className='row justify-content-center'>
                                <div className='col-12'>
                                    <form
                                        onSubmit={handleSubmit}
                                        method='post'
                                        name="indexform"
                                    >

                                        <Container fluid="true">

                                            <Row className='form-row full-gosnomer'>
                                                <Col className='mb-9 col gosnomer'>
                                                    <Form.Label className='suggest-text'>Номер автомобиля</Form.Label>
                                                    <InputGroup className="indexform-gosnomer">
                                                        <MaskedInput
                                                            mask={mask}
                                                            className='gosnomer-borders'
                                                            //style='text-transform: uppercase'
                                                            //mask="  a  999  aa"
                                                            //mask={['(', firstLetter, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}


                                                            guide={false}
                                                            placeholder="А 000 АА"
                                                            aria-label="gosnomer"
                                                            id="gosnomerfield"
                                                            name="gosnomerfield"
                                                            aria-describedby="basic-addon1"
                                                            defaultValue={nomerfield}
                                                            onFocus={() => setActiveField('nomer')}
                                                            //onBlur={() => setActiveField('default')}
                                                            onChange={(e) => setNomerfield(transliterate(e.target.value.toUpperCase()))}
                                                            required
                                                            onInvalid={e => e.target.setCustomValidity('Заполните госномер в формате A000AA')}
                                                            onInput={e => e.target.setCustomValidity('')}
                                                        />
                                                        {nomerfield?.length != 0 && nomerfield?.length < 6 ?
                                                            <>
                                                                {errors['nomer']}
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </InputGroup>
                                                </Col>


                                                <Col className='mb-3 col region'>
                                                    <div className="indexform-region">
                                                        <Form.Label className='suggest-text'>Регион</Form.Label>
                                                        <InputGroup>
                                                            <MaskedInput
                                                                mask={numberMask}
                                                                guide={false}
                                                                className='region-borders'
                                                                placeholder="77"
                                                                aria-label="region"
                                                                id="regionfield"
                                                                name="regionfield"
                                                                aria-describedby="basic-addon1"
                                                                defaultValue={regionfield}
                                                                onFocus={() => setActiveField('region')}
                                                                //onBlur={() => setActiveField('default')}
                                                                onBlur={() => getStsByNom()}
                                                                onChange={(e) => setRegionfield(e.target.value)}
                                                                required
                                                                onInvalid={e => e.target.setCustomValidity('Заполните регион')}
                                                                onInput={e => e.target.setCustomValidity('')}
                                                            />
                                                            {regionfield?.length != 0 && regionfield?.length < 2 ?
                                                                <>
                                                                    {errors['region']}
                                                                </>
                                                                :
                                                                <></>
                                                            }
                                                        </InputGroup>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>

                                        {showSts ?
                                            <>
                                                <Form.Label className='suggest-text'>Номер свидетельства о регистрации (СТС)</Form.Label>
                                                <InputGroup className="mb-3 indexform" id="exampleFormControlInput1">
                                                    <MaskedInput
                                                        mask={[digit, digit, fullLetterChar, fullLetterChar, digit, digit, digit, digit, digit, digit]}
                                                        guide={false}
                                                        className='fields-borders'
                                                        placeholder="00 00 111222"
                                                        aria-label="sts"
                                                        id="stsfield"
                                                        name="stsfield"
                                                        aria-describedby="basic-addon1"
                                                        defaultValue={stsfield}
                                                        onFocus={() => setActiveField('sts')}
                                                        //onBlur={() => setActiveField('default')}
                                                        onChange={(e) => setStsfield(transliterate(e.target.value.toUpperCase()))}
                                                        required
                                                        onInvalid={e => e.target.setCustomValidity('Заполните номер СТС в формате 00AA222333 или 0011222333')}
                                                        onInput={e => e.target.setCustomValidity('')}
                                                    />
                                                    {stsfield?.length != 0 && stsfield?.length < 10 ?
                                                        <>
                                                            {errors['sts']}
                                                        </>
                                                        :
                                                        <></>
                                                    }

                                                </InputGroup>
                                            </>

                                            : null }

                                        {/* {stsfield?.length > 9
                                            ?
                                            <>
                                                <Form.Label className='suggest-text'>Номер водительского удостверения</Form.Label>
                                                <InputGroup className="mb-3 indexform" id="exampleFormControlInput1">
                                                    <MaskedInput
                                                        mask={[digit, digit, fullLetterChar, fullLetterChar, digit, digit, digit, digit, digit, digit]}
                                                        guide={false}
                                                        className='fields-borders'
                                                        placeholder="00 АА 000000"
                                                        aria-label="email"
                                                        id="emailfield"
                                                        name="emailfield"
                                                        aria-describedby="basic-addon1"
                                                        defaultValue={vufield}
                                                        onChange={(e) => setVufield(e.target.value)}
                                                    />
                                                </InputGroup>
                                            </>
                                            : ''
                                        } */}


                                        <Form.Label className='suggest-text'>Электронная почта</Form.Label>
                                        <InputGroup className="mb-3 indexform" id="exampleFormControlInput1">
                                            <input
                                                className='fields-borders'
                                                placeholder="Для уведомлений о новых штрафах"
                                                aria-label="email"
                                                id="emailfield"
                                                name="emailfield"
                                                aria-describedby="basic-addon1"
                                                type="email"
                                                defaultValue={emailfield}
                                                onFocus={() => setActiveField('email')}
                                                //onBlur={() => setActiveField('default')}
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                                onInvalid={e => e.target.setCustomValidity('Заполните адрес электронной поты')}
                                                onInput={e => e.target.setCustomValidity('')}
                                            />
                                            {emailfield?.length != 0 && emailfield?.length < 5 ?
                                                <>
                                                    {errors['email']}
                                                </>
                                                :
                                                <></>
                                            }
                                        </InputGroup>

                                        {stsfield?.length > 9 && nomerfield?.length > 5 && regionfield?.length > 1 && emailfield?.length > 5 && emailfield?.length != 0 ?
                                            <>
                                                <Button variant="custom" className="fw-bold" type="submit" id="submit" name="submit" value="Submit" style={{ textTransform: 'none' }} >Проверить штрафы</Button>{' '}
                                            </>
                                            :
                                            <>
                                                <Button variant="custom" className="fw-bold" style={{ textTransform: 'none' }} >Проверить штрафы</Button>{' '}
                                            </>
                                        }
                                    </form>
                                </div>
                            </div>

                            <div className='row justify-content-center'>
                                <div className='col-12'>
                                    <div className='suggest-text'>
                                        Нажимая "Проверить штрафы" вы принимаете <a href="/oferta" className="customlink">лицензионное соглашение</a>
                                    </div>
                                </div>
                            </div>

                        </Card.Body>
                    </Card>
                </Col>

                <Col className='col-5 suggest-field d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block'>
                    <Card className='card-suggest righttips'>
                        <Card.Body>
                            <>
                                {hints[activeField]}
                            </>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Firstblock />
            <Secondblock />
            <Thirdblock />
            <Fourthblock />
            <Fifthblock />

        </Container>
    )
}

export default HomeGN;