import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import auto from './auto.svg';
import driver from './Components/Images/drivernf.svg';
import axios from "axios";
import Cookies from 'universal-cookie';
import ContentLoader from 'react-content-loader'
import ModalImage from "react-modal-image";
import Finespromo from "./Components/Finesblocks/Finespromo";


const API_SEARCH = 'https://api.shtrafy.info/api/search.php';
const API_SESSIONS = 'https://api.shtrafy.info/api/sessions.php';
const API_ADDTOBD = 'https://api.shtrafy.info/api/addfinestobd.php';
const API_GETPHOTO = 'https://api.shtrafy.info/api/getphoto.php';


// const API_SEARCH = 'http://localhost:8888/api/search.php';
// const API_SESSIONS = 'http://localhost:8888/api/sessions.php';
// const API_ADDTOBD = 'http://localhost:8888/api/addfinestobd.php';
// const API_GETPHOTO = 'http://localhost:8888/api/getphoto.php';


// const API_GETPHOTOLINKS = 'http://localhost:8888/api/classes/photoToStorage.php'



//Не обнаружено начислений по указанным данным
export const Fines = () => {

    const uit = (new URLSearchParams(window.location.search)).get("uit");
    const cookies = new Cookies();


    if (uit !== null) {
        cookies.set('token', uit, { path: '/' });
        //token = uit
    }


    const token = cookies.get('token')

    const [state, setState] = React.useState([]);
    const [photoState, setPhotoState] = React.useState([]);
    const [count, setCount] = useState(0);
    const [countPhoto, setCountPhoto] = useState(0);
    const [tips, setTips] = useState('Выполняется поиск штрафов ГИБДД...');

    const [emailfield, setEmail] = React.useState("");
    const [nomerfield, setNomerfield] = React.useState("");
    const [stsfield, setStsfield] = React.useState("");
    const [vufield, setVufield] = React.useState("");
    const [regionfield, setRegionfield] = React.useState("");
    const [fullNomer, setFullNomer] = React.useState("");


    const countRef = React.useRef(null);
    const countRefPhoto = React.useRef(null);
    const tipsArr = ['Запрашиваем данные...', 'Ожидаем ответ...', 'Ждем информацию...', 'Формируем список...', 'Готовим ответ...'];
    countRef.current = count;
    countRefPhoto.current = count;



    const TaxLoader = props => {
        return (
            <ContentLoader viewBox="0 0 778 116" width={778} height={116} {...props}>
                <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
                <rect x="28" y="29" rx="0" ry="0" width="128" height="32" />
                <rect x="28" y="71" rx="0" ry="0" width="265" height="32" />
                <rect x="29" y="116" rx="0" ry="0" width="349" height="32" />
            </ContentLoader>
        )
    }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    }



    React.useEffect(() => {
        const sendGetRequest = async () => {
            try {
                const res = await axios.get(API_SEARCH + '?token=' + token)
                const arr = []
                //console.log('res.data[0]: ', res.data[0])
                if (typeof res.data[0]['id'] !== "undefined") {
                    //console.log('res.data[0] !== undef: ', res.data[0])
                    Object.keys(res.data).forEach(key => arr.push(res.data[key]))
                    setState(arr);
                    setCount((prevCount) => prevCount + 30);

                    //await axios.post(API_ADDTOBD, JSON.stringify({ state: state, token: token, totalWithDiscount: totalWithDiscount, naimenovanie: naim, totalWithDiscountAndComission: totalWithDiscountAndComission }))


                } else {

                    Object.keys(res.data[0]).forEach(key => arr.push(res.data[0]))
                    setTips(tipsArr[getRandomInt(0, 5)])
                    setState(arr);
                    //console.log('arr: ', arr)
                }
            } catch (err) {
                console.error('error: ', err);
            }
        };

        const sendGetRequest2 = async () => {
            try {
                const res2 = await axios.get(API_SESSIONS + '?token=' + token)
                const arr2 = []
                Object.keys(res2.data).forEach(key => arr2.push(res2.data[key]))
                setStsfield(res2.data[0].sts)
                setEmail(res2.data[0].email)
                setVufield(res2.data[0].vu)
                setRegionfield(res2.data[0].reg)
                setNomerfield(res2.data[0].nom)
                setFullNomer(res2.data[0].nom+res2.data[0].reg)

            } catch (err) {
                console.error('error2 : ', err);
            }
        }

        
        

        sendGetRequest2();
        sendGetRequest();


        let interval = setInterval(() => {
            if (countRef.current >= 30) {
                clearInterval(interval);
            } else {
                sendGetRequest();
                setCount((prevCount) => prevCount + 1);
            }
        }, 15000);
    }, []);


    const result = state.reduce((a, b) => a + parseFloat(b.summa), 0)
    const tot = state?.map((prod) => (prod.total))
    const totDiscount = state?.map((prod) => (prod.totalWithDiscount))
    const totalWithDiscountAndComission = state?.map((prod) => (prod.totalWithDiscountAndComission))
    const sum = state?.map((prod) => (prod.summa))
    const naim = state?.map((prod) => (prod.naimenovanie))
    const legalAct = state?.map((prod) => (prod.legalAct))
    const year = state?.map((prod) => (prod.year))
    const asy = state?.map((prod) => (prod.asyncId))
    const photos = state?.map((prod) => (prod.photos))

    const myAsy = asy[0]
    const total = tot[0]
    const totalWithDiscount = totDiscount[0]
    const countState = state.length
    const navigate = useNavigate();

    function toSubmit(id) {
        fetch(API_ADDTOBD, {
            method: "POST",
            body: JSON.stringify({ state: state, token: token, totalWithDiscount: totalWithDiscount, naimenovanie: naim, legalAct: legalAct, totalWithDiscountAndComission: totalWithDiscountAndComission })
        })
            .then(() => {
                navigate('/submit', { state: { state: state, id: id, asyncId: myAsy } });
            })
            .catch((error) => console.log(error));
    }

    function sendYMEvent(cost,ymuin) {
        window.ym('93047780', "reachGoal", 'payFinesBtn')
        window.dataLayer.push({
            ecommerce: {
                currencyCode: 'RUB',
                add: {
                    products: [
                        {
                            id: ymuin,
                            price: cost,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
    }


    function sendYMFines(viewPrice,ymuin,name) {
        window.ym('93047780', "reachGoal", 'viewFines')
        window.dataLayer.push({
            ecommerce: {
                currencyCode: 'RUB',
                detail: {
                    products: [
                        {
                            id: ymuin,
                            name: name,
                            price: viewPrice
                        },
                    ],
                },
            },
        });
    }


    function getFineId(fineidf) {
        return fineidf
    }

    function getPhoto(uinid, fullNomer) {
        fetch(API_GETPHOTO, {
            method: "POST",
            body: JSON.stringify({ uinid: uinid, async: myAsy, fullNomer })
        })
            .then(async () => {
                try {
                    const resPhoto = await axios.post(API_GETPHOTO, JSON.stringify({ uinid: uinid, async: myAsy, fullNomer }))
                    const arrPhoto = []

                    // console.log('typeof resPhoto.data[uinid][0][0]: ', resPhoto.data[uinid][0][0]);
                    // console.log('resPhoto.data: ', resPhoto.data);

                    if (typeof resPhoto.data !== "undefined" && resPhoto.data[uinid][0][0] !== 'TAKENIN_NOTSENT') {
                        Object.keys(resPhoto.data).forEach(key => arrPhoto.push(resPhoto.data))
                        setPhotoState(arrPhoto);
                        //console.log('arrPhoto: ', arrPhoto);
                        //console.log('resPhoto.data[uinid][0][0]: ', resPhoto.data[uinid][0][0]);
                    } else {
                        //console.log('else resPhoto.data: ', resPhoto.data);
                        await new Promise(resolve => setTimeout(resolve, 10000));
                        await getPhoto(uinid,fullNomer);
                    }
                } catch (err) {
                    console.error('error: ', err);
                }

            })
            .catch((error) => console.log(error));
    }





    return (

        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem', }}>
            {/* {console.log('state: ', state)} */}
            <h1>Штрафы</h1>
            {state?.length === 0 ?

                <Container fluid="true">
                    <Row>
                        <Col>
                            <Container fluid="true">
                                <Row>
                                    <Col>
                                        <Card className='card-custom card-tips'>
                                            <Card.Body>
                                                <Container fluid="true">
                                                    <Row>
                                                        <Col className='col-8'>
                                                            <div>
                                                                {tips}<br /><TaxLoader />
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </Container>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                </Container>

                :
                <></>
            }


            <Container fluid="true">
                <Row>
                    <Col>
                        <Container fluid="true">

                            {state?.map((product) => (
                                
                                

                                <Row key={product.id ? product.id : 0}>
                                    
                                    

                                    {/* {console.log('product.id: ', product.id)} */}
                                    {product?.id !== 'err' ?
                                        <>
                                        {sendYMFines.call(product.finesUin,product.finalSumma,product.finesUin,product.naimenovanie)}
                                            <Col>
                                                <Card className='card-custom card-tips'>
                                                    <Card.Body>
                                                        <Container fluid="true">
                                                            <Row>
                                                                <Col className='col-8'>
                                                                    <div>
                                                                        <div>

                                                                            {product.finesUin ?
                                                                                <div>
                                                                                    {product?.postDate}
                                                                                    <img
                                                                                        className='icon-result'
                                                                                        src={auto}
                                                                                        alt="Автомобиль"
                                                                                    />
                                                                                    {nomerfield} {regionfield}
                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {tips}<br /><TaxLoader />
                                                                                </div>
                                                                            }

                                                                            <div className='bold-text'>
                                                                                {product?.naimenovanie} {product?.year}
                                                                            </div>

                                                                            {product.finesUin ?
                                                                                <div className='gray-text'>
                                                                                    УИН:
                                                                                </div>
                                                                                :
                                                                                <div className='gray-text'>

                                                                                </div>
                                                                            }
                                                                            <div className='black-text'>
                                                                                {product.finesUin}
                                                                            </div>

                                                                            {product.finesUin ?
                                                                                <div className='gray-text'>
                                                                                    Фото нарушения:
                                                                                </div>
                                                                                :
                                                                                <div className='gray-text'>

                                                                                </div>
                                                                            }
                                                                            {/* {product.finesUin && product.finesUin.length <= 20 && product.finesUin.startsWith('188') ? */}
                                                                            {product.finesUin && product.finesUin.length <= 25 ?
                                                                                <div className='black-text'>

                                                                                    {product.photos && product.photos !== 'null' && product.photos !== false ?
                                                                                        <>
                                                                                            {/* {console.log(Object.values(product.photos)[0][0])} */}


                                                                                            {Object.values(product.photos)[0][0] === 'CANCELED'
                                                                                                ?
                                                                                                <>
                                                                                                    Фотографии не найдены
                                                                                                </>
                                                                                                : Object.values(product.photos)[0][0] === 'TAKENIN_NOTSENT'
                                                                                                    ?
                                                                                                    <>
                                                                                                        Идет поиск фотографий
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {Object.keys(Object.values(product.photos)).map(photoId => (

                                                                                                            <ModalImage
                                                                                                                key={photoId}
                                                                                                                className='image-humbnail'
                                                                                                                small={Object.values(product.photos)[photoId]}
                                                                                                                large={Object.values(product.photos)[photoId]}
                                                                                                                alt="Фотография нарушения"
                                                                                                            />

                                                                                                        ))
                                                                                                        }
                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                        :
                                                                                                                    
                                                                                        photoState?.length !== 0 ?
                                                                                            <>
                                                                                                {photoState?.map((photo, index) => (
                                                                                                    <>
                                                                                                        {photo[product.finesUin]?.length !== 0 ?
                                                                                                            <>
                                                                                                                {/* {console.log('photo[product.finesUin].length: ', photo[product.finesUin]?.length)} */}
                                                                                                            </>
                                                                                                            :
                                                                                                            photo[product.finesUin].length === 0 ?
                                                                                                                <a onClick={() => { getPhoto(product.finesUin, fullNomer) }}><Button variant="link" className="link-button" id={product.finesUin}>Запросить фото</Button></a>
                                                                                                                :
                                                                                                                <></>
                                                                                                        }
                                                                                                    </>
                                                                                                ))}
                                                                                            </>
                                                                                            : <>
                                                                                                <a onClick={() => { getPhoto(product.finesUin, fullNomer) }}><Button variant="link" className="link-button" id={product.finesUin}>Запросить фото</Button></a>
                                                                                            </>
                                                                                    }

                                                                                    <div>
                                                                                        {photoState?.map((photo, index) => (
                                                                                            <span key={index}>
                                                                                                {Object.keys(photo)[0] === product.finesUin ?
                                                                                                    <>
                                                                                                        {Object.keys(photo[Object.keys(photo)[0]]).map((keyName, i) => (
                                                                                                            <>
                                                                                                                {photo[Object.keys(photo)[0]][0][0] === 'CANCELED'
                                                                                                                    ?
                                                                                                                    <>
                                                                                                                        Фотографии не найдены
                                                                                                                    </>
                                                                                                                    : photo[Object.keys(photo)[0]][0][0] === 'TAKENIN_NOTSENT'
                                                                                                                        ?
                                                                                                                        <>
                                                                                                                            Идет поиск фотографий
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            <ModalImage
                                                                                                                                className='image-humbnail'
                                                                                                                                key={i}
                                                                                                                                small={photo[Object.keys(photo)[0]][i]}
                                                                                                                                large={photo[Object.keys(photo)[0]][i]}
                                                                                                                                alt="Фотография нарушения"
                                                                                                                            />
                                                                                                                        </>
                                                                                                                }
                                                                                                            </>
                                                                                                        ))}
                                                                                                    </>
                                                                                                    :
                                                                                                    <></>
                                                                                                }
                                                                                            </span>
                                                                                        ))}
                                                                                    </div>

                                                                                </div>
                                                                                :
                                                                                <div className='black-text'>
                                                                                    {product.finesUin ?
                                                                                        <>
                                                                                            Фото нет в базе ГИБДД
                                                                                        </>

                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                    }

                                                                                </div>
                                                                            }

                                                                            {product?.client != '-' && product.finesUin ?
                                                                                <div className='gray-text'>
                                                                                    Кому:
                                                                                </div>
                                                                                :
                                                                                <div className='gray-text'>

                                                                                </div>
                                                                            }
                                                                            {product?.client != '-' && product.finesUin ?
                                                                                <div className='black-text'>
                                                                                    {product?.client}
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                            }

                                                                            {product.offenseCoordinates || product?.finesPlace ?
                                                                                <>
                                                                                    <div className='gray-text'>
                                                                                        Место нарушения:
                                                                                    </div>
                                                                                    <div className='black-text'>
                                                                                        {product?.finesPlace}
                                                                                    </div>

                                                                                    {product.offenseCoordinates ?
                                                                                        <img
                                                                                            src={`https://static-maps.yandex.ru/1.x/?ll=${product?.long},${product?.lat}&size=400,250&z=15&l=map&pt=${product?.long},${product?.lat},comma`}
                                                                                            alt="карта места нарушения"
                                                                                        />
                                                                                        :
                                                                                        <></>
                                                                                    }

                                                                                </>
                                                                                :
                                                                                <>

                                                                                </>
                                                                            }



                                                                            {product.finesUin && product.fineDate ?
                                                                                <div className='gray-text'>
                                                                                    Дата нарушения:
                                                                                </div>
                                                                                :
                                                                                <>

                                                                                </>
                                                                            }
                                                                            <div className='black-text'>
                                                                                {product?.fineDate}
                                                                            </div>

                                                                            {product.finesUin ?
                                                                                <div className='gray-text'>
                                                                                    Срок оплаты:
                                                                                </div>
                                                                                :
                                                                                <>

                                                                                </>
                                                                            }

                                                                            {product.finesUin ?
                                                                                <div className='black-text'>
                                                                                    до {product?.postDateEndFormat}
                                                                                </div>
                                                                                :
                                                                                <>

                                                                                </>
                                                                            }

                                                                            {product.finesUin || product.finesDepot ?
                                                                                <div className='gray-text'>
                                                                                    Постановление вынесено:
                                                                                </div>
                                                                                :
                                                                                <div className='gray-text'>

                                                                                </div>
                                                                            }
                                                                            {product.finesDepot ?

                                                                                <div className='black-text'>
                                                                                    {product?.finesDepot}
                                                                                </div>
                                                                                :
                                                                                <div className='black-text'>
                                                                                    {product?.wireusername}
                                                                                </div>
                                                                            }

                                                                            {product.finesUin && product.fsspDeloNum ?
                                                                                <>
                                                                                    <div className='gray-text'>

                                                                                    </div>
                                                                                    <Card border="danger" className='card-custom'>

                                                                                        <Card.Subtitle>
                                                                                            <div className='fssp-subtitle-first'>Исполнительное производство</div>
                                                                                            <div className='fssp-subtitle'>Федеральной Службы Судебных Приставов</div>
                                                                                        </Card.Subtitle>
                                                                                        <div className='fssp-subtitle'> {product.fsspDeloNum}</div>
                                                                                        <Card.Body>
                                                                                            <div className='gray-text-fssp-first'>УИН</div>
                                                                                            <div className='black-text-fssp'>{product.finesUin}</div>

                                                                                            <div className='gray-text-fssp'>Сумма взыскания</div>
                                                                                            <div className='black-text-fssp'>{product.finalSumma} руб.</div>

                                                                                            <div className='gray-text-fssp'>Отдел судебных приставов</div>
                                                                                            <div className='black-text-fssp'>{product.fsspContact}</div>
                                                                                        </Card.Body>
                                                                                    </Card>
                                                                                </>
                                                                                :
                                                                                <div className='gray-text'>

                                                                                </div>

                                                                            }

                                                                        </div>

                                                                    </div>
                                                                </Col>
                                                                <Col className='col-4'>
                                                                    <div className='taxsumma'>
                                                                        {product.discountSumma !== 0 ?
                                                                            <>
                                                                                {product.finesUin ?
                                                                                    <>
                                                                                        <div className='finesSumma'>

                                                                                            <div className='gray-underline-text'>
                                                                                                {product.summa} руб.
                                                                                            </div>
                                                                                            <div className='summa-text'>
                                                                                                {product.finalSumma} руб.
                                                                                            </div>
                                                                                        </div>
                                                                                    </> :
                                                                                    <>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <div>
                                                                                <span className='summa-text'>
                                                                                    {product.finalSumma} руб.
                                                                                </span>
                                                                            </div>
                                                                        }
                                                                    </div>



                                                                    {!product.fsspDeloNum ?
                                                                        <>
                                                                            {product.discountSumma !== 0 ?
                                                                                <div>
                                                                                    {product.finesUin ?
                                                                                        <>
                                                                                            <div>
                                                                                                <a onClick={() => { toSubmit(product.id); sendYMEvent(product.finalSumma,product.uinid) }}> <Button variant="custom" className="pay-btn">Оплатить</Button> </a>
                                                                                            </div>
                                                                                            <div className='blue-text-skidka'>
                                                                                                Скидка на оплату {product.discountSize}%
                                                                                            </div>
                                                                                            <div className='gray-text-skidka'>
                                                                                                Сэкономьте {product.discountSumma} руб. при оплате этого штрафа сейчас.
                                                                                            </div>
                                                                                            <div className='gray-text-skidka'>
                                                                                                Скидка действует до {product.discountDate}
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                    }

                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {product.finesUin ?
                                                                                        <>
                                                                                            <div>
                                                                                                <a onClick={() => { toSubmit(product.id); sendYMEvent(product.finalSumma,product.uinid)}}> <Button variant="custom" className="pay-btn">Оплатить</Button> </a>
                                                                                            </div>
                                                                                            <div className='blue-text-skidka'>
                                                                                                Срок оплаты истек!
                                                                                            </div>
                                                                                            <div className='blue-text-skidka'>
                                                                                                Дело могут передать в суд!
                                                                                            </div>
                                                                                            <div className='gray-text-skidka'>
                                                                                                Обязательство по уплате штрафа остается в силе.
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {product.discountSumma !== 0 ?
                                                                                <div>
                                                                                    {product.finesUin ?
                                                                                        <>
                                                                                            <div>
                                                                                                <a onClick={() => { toSubmit(product.id); sendYMEvent(product.finalSumma,product.uinid) }}> <Button variant="custom" className="pay-btn">Оплатить</Button> </a>
                                                                                            </div>
                                                                                            <div className='blue-text-skidka'>
                                                                                                Скидка на оплату {product.discountSize}%
                                                                                            </div>
                                                                                            <div className='gray-text-skidka'>
                                                                                                Сэкономьте {product.discountSumma} руб. при оплате этого штрафа сейчас.
                                                                                            </div>
                                                                                            <div className='gray-text-skidka'>
                                                                                                Скидка до {product.discountDate}
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                    }

                                                                                </div>
                                                                                :
                                                                                <div>
                                                                                    {product.finesUin ?
                                                                                        <>
                                                                                            <div>
                                                                                                <a onClick={() => { toSubmit(product.id); sendYMEvent(product.finalSumma,product.uinid) }}> <Button variant="custom" className="pay-btn">Оплатить</Button> </a>
                                                                                            </div>
                                                                                            <div className='blue-text-skidka'>
                                                                                                Штраф передан судебным приставам
                                                                                            </div>

                                                                                            <div className='gray-text-skidka'>
                                                                                                Если вы уже оплатили этот штраф, обратитесь в отдел судебных приставов.
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }

                                                                </Col>
                                                            </Row>
                                                        </Container>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </>
                                        :
                                        <>

                                            <Col>
                                                <Card className='card-custom card-tips'>
                                                    <Card.Body>
                                                        <Container fluid="true" >

                                                            <Row className='row justify-content-center'>
                                                                <Col className='col-8'>
                                                                    <div className='row justify-content-center qclass'>
                                                                        {product.naimenovanie}
                                                                    </div>
                                                                    <div className='row justify-content-center'>
                                                                        <img
                                                                            className='icon-fines-no-found'
                                                                            src={driver}
                                                                            alt="Штрафы не найдены"
                                                                        />
                                                                    </div>
                                                                </Col>

                                                            </Row>
                                                        </Container>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                                                    
                                        </>
                                    }
                                </Row>


                            )
                            )}

                        </Container>
                    </Col>

                    {result ?
                        <>

                            <Col className='col-4 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block'>
                                {/* <Card className='card-custom-right card-tips'>
                                    <Card.Body>
                                        <div>
                                            <div>
                                                <div className='bold-text'>
                                                    Найден {countState} неоплаченный штраф
                                                </div>

                                                <div className='black-text'>
                                                    На общую сумму: <strong>{totalWithDiscount} руб. </strong>с учетом скидок
                                                </div>
                                                <div className='black-text'>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='taxsumma'>

                                        </div>

                                        <div>
                                            <a onClick={() => { toSubmit(all) }}> <Button variant="custom" className="pay-btn">Оплатить все</Button> </a>
                                        </div>
                                    </Card.Body>
                                </Card> */}

                                <Card className='card-custom-right card-tips'>
                                    <Card.Body>
                                        <Finespromo />
                                    </Card.Body>
                                </Card>

                            </Col>
                        </>
                        :
                        <div>
                        </div>
                    }

                </Row>
            </Container>
        </Container >
    )
}
export default Fines;