import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const Fail = () => {

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>Что-то пошло не так &#128549;</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <>
                                <div>
                                    <span>
                                        <p>При оплате возникла проблема.</p>
                                        <p>Деньги не были списаны, штраф не оплачен.</p>
                                        <p>Возможно, была допущена ошибка при вводе данных карты или ошибочно введен код подтверждения.</p>
                                        <p>Попробуйте оплатить еще раз.</p>
                                        <p><a href="/fines" className="customlink">Перейти к штрафам</a>.</p>
                                    </span>
                                </div>
                            </>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}
export default Fail