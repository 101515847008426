import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const About = () => {

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>О проверке</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <p>Для поиска штрафов - мы используем только официальные источники</p>
                            <hr />
                            <p>Когда вы используете наш сервис для поиска штрафов ГИБДД, вы можете быть уверены, что получаете информацию только из официальных источников.</p>
                            <p>Мы не используем для получения информации не официальные источники, которые могут содержать неточную информацию или ошибки.</p>
                            <hr />
                            <p>Если вы хотите проверить информацию о штрафах ГИБДД, мы гарантируем, что вы найдете только официальную информацию на нашем сайте. Если у вас есть какие-либо вопросы или сомнения относительно информации на нашем сайте, пожалуйста, свяжитесь с нами, и мы с радостью поможем вам.</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default About;
