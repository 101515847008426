import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import Finephoto from '../Images/finephoto.png';

export const Fourthblock = () => {

    return (
        <>
            <Card className='card-info-fourthindex'>
                <Container>
                    <Row className='row-custom'>
                        <Col className='leftfourthindex'>
                            <Card.Body>
                                <Card.Title><h2 className='h2class'>Гарантия погашения штрафа</h2></Card.Title>
                                <p><span className='discounttips'>Мы гарантируем автоматическое исполнение штрафа через базу ГИС ГМП или вернем все денежные средства.</span></p>
                                <p><span className='discounttips'>После проведения платежа вам на электронную почту будет отправлена квитанция об оплате. Квитанция является официальным документом подтверждающим факт оплаты</span></p>
                                <p><span className='discounttips'>Для оплаты штрафов мы принимаем банковские карты МИР, VISA, MasterCard. Платежи защищены по стандарту PCI DSS. Платежи обрабатывает: НКО «МОНЕТА» — лицензия ЦБ РФ 3508-К от 02.07.2012</span></p>
                                <p><span className='discounttips'>При любых сложностях - обращайтесь в нашу службу технической поддержки, мы поможем решить ваши вопросы.</span></p>
                            </Card.Body>
                        </Col>
                        
                        
                    </Row>
                </Container>
            </Card>


        </>
    )
}
export default Fourthblock

