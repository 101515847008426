import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NaviBar from './Components/Navibar';
import ButtonBar from './Components/Buttonbar';
import FooterBar from './Components/Footer';
import Home from './Home.js';
import HomeGN from './HomeGN.js';


import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from "react-router-dom";

import {Fines} from './Fines';

import {Submit} from './Submit';
import {Payment} from './Payment';
import {Security} from './Security';
import {Contacts} from './Contacts';
import {About} from './About';
import {Aboutpay} from './Aboutpay';
import {Faq} from './Faq';
import {Success} from './Success';
import {Payurl} from './Payurl';
import {Oferta} from './Oferta';
import {Fail} from './Fail';
import NotFound from './NotFound';

{!['/payment', '/submit', '/success', '/fail', '/payurl'].includes(window.location.pathname) && <ButtonBar /> }
function App() {

  return (
    <>
      <Router>
      {/* <NaviBar /> */}
      
      {!['/payment', '/submit', '/success', '/fail', '/payurl'].includes(window.location.pathname) && <NaviBar /> }
      <Routes>
      
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/fines" element={<Fines/>} />
        <Route exact path="/submit" element={<Submit/>} />
        <Route exact path="/payment" element={<Payment/>} />
        <Route exact path="/security" element={<Security/>} />
        <Route exact path="/contacts" element={<Contacts/>} />
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/aboutpay" element={<Aboutpay/>} />
        <Route exact path="/faq" element={<Faq/>} />
        <Route exact path="/oferta" element={<Oferta/>} />
        <Route exact path="/success" element={<Success/>} />
        <Route exact path="/payurl" element={<Payurl/>} />
        <Route exact path="/return" element={<Fines/>} />
        <Route exact path="/fail" element={<Fail/>} />
        <Route exact path="/gosnomer" element={<HomeGN/>} />

        
        
        <Route path='*' element={<NotFound status={404}/>}  />
       
      </Routes>
      
      </Router>
      {!['/payment', '/submit', '/success', '/fail', '/payurl'].includes(window.location.pathname) && <FooterBar /> }
      {/* <FooterBar /> */}
      </>
  );
}

export default App;