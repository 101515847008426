import React from "react";

export const Noerrors = () => {

    return (
        <>
          
        </>
    )
}
export default Noerrors