import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Road from '../Images/road.svg';

export const Firstblock = () => {

    return (
        <>
            <Card className='card-info-firstindex'>
                <Container>
                    <Row className='row-custom'>
                        <Col className='leftindextext'>
                            <Card.Body>
                                <Card.Title><h2 className='h2class'>Оплата штрафов со скидкой</h2></Card.Title>
                                <p><span className='discounttips'>Наши регулярные проверки помогут вам оплачивать штрафы вовремя и воспользоваться скидкой 50% при своевременной оплате</span></p>
                            </Card.Body>
                        </Col>
                        <Col className="centerindextext col-5 suggest-field d-none d-lg-block" >
                            <img
                                className='grs-img'
                                src={Road}
                                alt="Госномер авто"
                            />
                        </Col >
                        <Col className="centerindextextsm col-5 suggest-field d-none d-sm-block d-lg-none d-xl-none" >
                            <img
                                className='grs-img'
                                src={Road}
                                alt="Госномер авто"
                            />
                        </Col >


                        
                        <Col className='rightindextext col-5 suggest-field d-none d-lg-block'>
                            <Card.Body>
                                <p><span className='bigdiscount'>50%</span></p>
                                <div className='discounttips2'>Скидка действует первые 20 дней после вынесения постановления о штрафе</div>
                                
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>


        </>
    )
}
export default Firstblock

