import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import gplogo from './Images/gp.svg';
import aslogo from './Images/as.svg';
import mobileFinesLogo from './Images/shtrafy-app-logo.svg'
import bankcardlogo from './Images/bankcardlogo.png'


const FooterBar = () => (

    <Container>
        <Container style={{ display: 'flex', justifyContent: 'flex-end', padding: '20px' }} >
            <Row>
                {/* <Col xs={6}>
                    <Row>
                        <Col xs={3}  className='d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block'>
                            <img
                                className='mobilelogo-img'
                                src={mobileFinesLogo}
                                alt="Мобильное приложение Штрафы.Инфо"
                            />
                        </Col>

                        <Col xs={9}>
                            <h6>Установите наше мобильное приложение</h6>
                            <p>И мы уведомим вас о появлении новых налоговых Штрафов</p>
                            <img
                                className='storelogo-img'
                                src={gplogo}
                                alt="Штрафы.Инфо для Android в Play Market"
                            />

                        <img
                                className='storelogo-img'
                                src={aslogo}
                                alt="Штрафы.Инфо для iPhone в Apple AppStore"
                            />
                        </Col>


                    </Row>
                </Col> */}
                <Col className='col-6 d-block d-sm-block d-sm-block d-md-block d-md-block d-lg-block'>
                    <Row className='footerVisible'>
                        <Col className='col-6 d-block d-sm-block d-sm-block d-md-block d-md-block d-lg-block'>
                            <h6>Пользователям</h6>
                            <p id='p2'><a href="/faq" className="customlink">Вопросы и ответы</a></p>
                            <p id='p2'><a href="mailto:info@shtrafy.info" className="customlink">Написать нам</a></p>
                        </Col>

                        <Col className='col-6 d-block d-sm-block d-sm-block d-md-block d-md-block d-lg-block'>
                            <h6>О компании</h6>
                            <p id='p2'><a href="/security" className="customlink">Безопасность</a></p>
                            <p id='p2'><a href="/contacts" className="customlink">Контакты</a></p>
                            <p id='p2'><a href="/about" className="customlink">О проверке</a></p>
                            <p id='p2'><a href="/aboutpay" className="customlink">Об оплате</a></p>
                        </Col>
                    </Row>
                </Col>


                <Col className='col-6 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block'>
                    <Row>
                        <Col className='col-6 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block'>
                            <h6>ООО "ДНК МОБАЙЛ"</h6>
                            <p id='p2'>ИНН: 5402017733</p>
                            <p id='p2'>ОГРН: 1165476089261</p>
                            <p id='p2'>630001, Россия, г.Новосибирск, ул.Каменская 32, офис 607а</p>
                        </Col>

                        <Col className='col-6 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block'>
                            <h6>Мы принимаем карты:</h6>
                            <img
                                className='banklogo-img'
                                src={bankcardlogo}
                                alt="принимаем карты МИР, VISA, MasterCard"
                            />
                            <p id='p2'>Платежи обрабатывает: НКО «МОНЕТА» — лицензия ЦБ РФ 3508-К от 02.07.2012</p>
                        </Col>
                    </Row>
                </Col>




            </Row>


        </Container>


    </Container>




)
export default FooterBar;