import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const Aboutpay = () => {

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>Об оплате</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>

                        <p>На нашем сервисе вы можете безопасно и быстро оплатить штрафы ГИБДД. Мы гарантируем, что все платежи проходят через платежную систему Монета.Ру, которая обеспечивает надежную защиту вашей личной информации и данных банковской карты.</p>
                        <hr />
                        <p>Напоминаем, что поиск штрафов ГИБДД происходит в официальных государственных источниках, что гарантирует точность и актуальность информации.</p>
                        <p>Вы можете быть уверены, что сумма штрафа, указанная на нашем сайте, соответствует сумме, которую необходимо оплатить.</p>

                        <p>После успешной оплаты мы сразу уведомляем ГИБДД и Казначейство о том, что ваш штраф оплачен. Вам не нужно ничего дополнительно отправлять или сообщать об оплате. Кроме того, мы высылаем вам квитанцию об оплате на ваш электронный адрес.</p>

                        <p>Благодарим вас за использование нашего сервиса и уверены, что вы будете довольны скоростью и удобством оплаты штрафов ГИБДД на нашем сайте. Если у вас остались какие-либо вопросы или замечания, пожалуйста, свяжитесь с нашей службой поддержки. Мы всегда готовы помочь вам.</p>

                        <hr />
                        <p>Мы ценим каждого нашего пользователя и всегда стремимся предоставлять наш сервис на высшем уровне. Чтобы обеспечить быстрое и качественное обновление базы данных о штрафах ГИБДД, необходимо постоянно инвестировать в технические ресурсы и развивать наш сервис.</p>
                        <p>Комиссия, которую мы взимаем за предоставление услуг по поиску и оплате штрафов, позволяет нам развиваться и делать наш сервис еще лучше.</p>
                        <p>Наша команда постоянно работает над улучшением сервиса, чтобы сделать его еще более удобным и надежным для наших пользователей. Мы также гарантируем полную прозрачность нашей ценовой политики и не взимаем никаких скрытых комиссий или дополнительных платежей.</p>
                        <hr />
                        <p>Мы благодарим вас за выбор нашего сервиса и обещаем продолжать работать над тем, чтобы делать его еще более качественным и удобным для вас. Если у вас есть какие-либо вопросы или пожелания, пожалуйста, не стесняйтесь обращаться к нашей службе поддержки, мы всегда готовы помочь вам в любое время.</p>

                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Aboutpay;
