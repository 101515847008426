import React from "react";
import { Container, Row, Col, Card, Button, InputGroup, Form } from "react-bootstrap";
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from "axios";
import recivepay from './Components/Images/kvitancia.svg';

const API_GETPAYDATAFROMBD = 'https://api.shtrafy.info/api/submitpay.php'
const INVOICE_PATH = 'https://api.shtrafy.info/api/invoiceRequest.php';

// const API_GETPAYDATAFROMBD = 'http://localhost:8888/api/submitpay.php'
// const INVOICE_PATH = 'http://localhost:8888/api/invoiceRequest.php';

export const Submit = () => {
    const cookies = new Cookies();
    const [getstate, setGetstate] = React.useState([]);
    const comission = 0.1;
    const [commis, setCommis] = React.useState([]);
    const [client, setClient] = React.useState();
    const [email, setEmail] = React.useState();

    const [totalWithCommis, setTotalWithCommis] = React.useState([]);
    const location = useLocation();
    const [summaOplaty, setSummaoplaty] = React.useState([]);
    const [summFee, setSummFee] = React.useState();
    const token = cookies.get('token')
    const [payLink, setPayLink] = React.useState();
    const [uin, setUin] = React.useState();



    var totalComission = 0;
    const navigate = useNavigate();

    const id = location.state.id
    const asy = location.state.asyncId;

    function summaOplatyFloat() {
        const arrFee = []
        summaOplaty.map(product => (
            arrFee.push(product * comission)
        ))

        const result = arrFee.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

        totalComission = result
    }
    summaOplatyFloat()

    function handleSubmit(e) {
        navigate('/payment', { state: { link: payLink, fio: client, email: email, uin: uin, token: token } })
    }



    React.useEffect(() => {
        const sendGetRequest = async () => {
            try {
                const res = await axios.get(API_GETPAYDATAFROMBD + '?token=' + token + '&id=' + id)
                const arrGet = []

                if (typeof res.data[0]['id'] !== "undefined") {
                    Object.keys(res.data).forEach(key => arrGet.push(res.data[key]))
                    setCommis(res.data[0]['comission']);
                    setTotalWithCommis(res.data[0]['totalWithComission']);

                    if (res.data[0]['client'] !== '-') {
                        setClient(res.data[0]['client']);
                    }

                    setEmail(res.data[0]['email']);
                    setGetstate(arrGet);


                    setUin(res.data[0]['finesUin']);
                    // console.log('res.data[0][finesUin]: ', res.data[0]['finesUin']);

                } else {
                    // console.log('ожидание данных');
                }
            } catch (err) {
                console.error('error: ', err);
            }
        }
        sendGetRequest()



        const getLinkRequest = async () => {
            try {
                const res2 = await axios.get(INVOICE_PATH + '?asyncId=' + asy + '&fineid=' + id + '&client=' + client + '&token=' + token + '&email=' + email)
                setPayLink(res2.data);
            } catch (err) {
                console.error('error: ', err);
            }
        }
        getLinkRequest()


    }, [])

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem', }}>
            <h1>Подтверждение оплаты штрафа</h1>
            <Container fluid="true">
                <Row>
                    <Col>
                        <Container fluid="true">
                            <Row>

                                <Col>
                                    <Card className='card-custom'>
                                        <Card.Body>
                                            <>
                                                {getstate?.map((sub) => (
                                                    <Container fluid="true" key={id ? id : 0}>
                                                        <>
                                                            <Row>
                                                                <Col className='col-9'>
                                                                    <div>
                                                                        {sub.postDate} УИН: {sub.finesUin}
                                                                    </div>
                                                                    <div>
                                                                        <strong>{sub.naimenovanie} </strong>
                                                                    </div>
                                                                </Col>
                                                                <Col>
                                                                    <div className='regular-text-right'>
                                                                        <div>{sub.totalWithDiscount} руб.</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                        </>
                                                    </Container>
                                                ))
                                                }
                                            </>

                                            <div>
                                                <div>
                                                    <Container fluid="true">
                                                        <Row>
                                                            <Col className='col-9'>
                                                                <div>
                                                                    Сервисный сбор
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className='regular-text-right'>
                                                                    {commis} руб.
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                            <hr />

                                            <div>
                                                <div>
                                                    <Container fluid="true">
                                                        <Row>
                                                            <Col className='col-9'>
                                                                <div className='bold-text'>
                                                                    Итого к оплате:
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className='bold-text-right'>
                                                                    {totalWithCommis} руб.
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>

                        <form
                            onSubmit={handleSubmit}
                            method='post'
                            name="paymentform"
                        >

                            <Container fluid="true" className='confirm-container col-12'>

                                <Card className='card-custom'>
                                    <div className='row justify-content-center'>
                                        <div className='col-9'>
                                            <Card.Body>
                                                <Form.Label className='suggest-text'>Фамилия Имя Отчество платильщика</Form.Label>
                                                <InputGroup className="mb-3 indexform">
                                                    <Form.Control
                                                        placeholder="Введите ФИО платильщика"
                                                        aria-label="fio"
                                                        aria-describedby="basic-addon1"
                                                        defaultValue={client}
                                                        onChange={(e) => setClient(e.target.value)}
                                                        required
                                                    />
                                                </InputGroup>
                                                <Form.Label className='suggest-text'>Электронная почта</Form.Label>
                                                <InputGroup className="mb-3 indexform" id="exampleFormControlInput1">
                                                    <Form.Control
                                                        placeholder="Введите электронную почту"
                                                        aria-label="email"
                                                        aria-describedby="basic-addon1"
                                                        type="email"
                                                        defaultValue={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                    />
                                                </InputGroup>
                                                <Form.Label className='suggest-text-bottom'>Мы отправим квитанцию об оплате на указанную электронную почту</Form.Label>

                                                {/* <a onClick={() => { toSubmit(product.id) }}> <Button variant="custom" className="pay-btn">Оплатить</Button> </a>         */}

                                                {/* <a href="/payment"> */}
                                                <Button variant="custom" type="submit" className="fw-bold" style={{ textTransform: 'none' }}>
                                                    Перейти к оплате
                                                </Button>{' '}
                                                {/* </a> */}
                                                <p className="pay-submit-gray-text">Нажимая «Перейти к оплате» вы соглашаетесь с <a href="/oferta" className="customlink">лицензионным соглашением</a>.</p>
                                                <p className="pay-submit-gray-text">Мы не принимаем и не храним данные банковских карт. Все операции с банковскими картами по оплате штрафов производит оператор по переводу денежных средств НКО «МОНЕТА.РУ»</p>

                                            </Card.Body>

                                        </div>
                                    </div>
                                </Card>

                            </Container>
                        </form>
                    </Col>

                    <Col className='col-4 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block'>
                        <div>
                            <p className="pay-recive-text">После оплаты, на указанный адрес электронной почты, вам будет отправлена банковская квитанция и мы моментально уведомим ГИБДД и казначейство об оплате</p>
                            <img
                                className='payrecive-img'
                                src={recivepay}
                                alt="Где найти свой ИНН?"
                            />
                        </div>

                        <div className='taxsumma'>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Submit;