import React from "react";

export const Emailerrors = () => {

    return (
        <>
          <div className="suggest-tips">Заполните адрес электронной поты</div>  
        </>
    )
}
export default Emailerrors