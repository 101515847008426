import React from "react";
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

const API_UPDATEPAYDATA = 'https://api.shtrafy.info/api/updatePayTransactions.php'
// const API_UPDATEPAYDATA = 'http://localhost:8888/api/updatePayTransactions.php'

export const Payment = () => {

    const location = useLocation();
    const payLink = location.state.link
    const fio = location.state.fio
    const email = location.state.email
    const uin = location.state.uin
    const token = location.state.token


    React.useEffect(() => {

        const updatePayData = async () => {
            try {
                await axios.post(API_UPDATEPAYDATA, JSON.stringify({ fio: fio, email: email, uin: uin, token: token }))
            } catch (err) {
                console.error('error: ', err);
            }
        }
        updatePayData()
    }, [])




    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '0rem', }}>

            <Container fluid="true">
                <Row>
                    <Col>
                        <Container fluid="true" style={{ height: '730px', }}>
                            <iframe className="widget" style={{ height: '100%', width: '100%', backgroundColor: '#F4F5FA', }} src={payLink}>
                                Ваш браузер не поддерживает iframe! Оплата не возможна.
                            </iframe>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Payment;
