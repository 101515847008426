import React from "react";
import eml from '../Images/eml.svg';

export const Nomertips = () => {

    return (
        <>
            <div className='righttips '>
                <h5 >Адрес электронной почты </h5>
            </div>
            <div className='righttips '>
                <img
                    className='grs-img '
                    src={eml}
                    alt="email для уведомлений о новых штрафах"
                />
            </div>
            <div className='righttips '>
                <p>Уведомим о появлении новых штрафов</p>
            </div>
            <div className='righttips '>
                <p>Вышлем квитанцию об оплате</p>
            </div>
            <div className='righttips '>
                <p>Спамить не будем &#128580;</p>
            </div>


        </>
    )

}
export default Nomertips