import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

export const Faq = () => {

    return (
        <Container style={{ paddingTop: '1rem', paddingBottom: '2rem' }}>
            <h1>Вопросы и ответы</h1>
            <Row>
                <Col>
                    <Card className='card-custom'>
                        <Card.Body>
                            <p className='qclass'>Ниже вы найдете ответы на некоторые из самых часто задаваемых вопросов о сервисе онлайн поиска и оплаты штрафов ГИБДД Штрафы.Инфо</p>
                            <hr />
                            <div className='qclass'>1. Как использовать сервис для поиска и оплаты штрафов ГИБДД?</div>
                            <div>Для использования нашего сервиса вам необходимо ввести номер вашего автомобиля (госномер), номер свидетельства регистрации транспотного средства (СТС, розовая ламинированная карточка), номер водительского удостоверения и адрес вашей электронной почты.
                            Наш сервис запросит данные о имеющихся штрафах в государственных базах и покажет вам найденные штрафы. Затем вы можете выбрать способ оплаты и завершить процесс оплаты онлайн.</div>
                            <p></p>
                            <div className='qclass'>2. Какие способы оплаты доступны на вашем сайте?</div>
                            <div>На нашем сайте доступна оплата банковской картой МИР, Visa, MasterCard</div>
                            <p></p>
                            <div className='qclass'>3. Я ошибся при вводе номера своего водительского удостоверения, номера свидетельства о регистрации транспортного средства или адреса электронной почты. Что делать?</div>
                            <div>Если вы допустили ошибку при вводе номера своего водительского удостоверения или номера свидетельства о регистрации транспортного средства - наш сервис не сможет найти ваши штрафы. Попробуйте вернуться на главную страницу и выполнить повторный поиск.</div>
                            <div>Если вы ошиблись в вводе адреса электронной почты - мы не сможем выслать вам квитанцию об оплате и своевременно уведомить вас о появлении новых штрафов, в таком случае - пожалуйста, свяжитесь с нами, и мы поможем вам решить эту проблему.</div>
                            <p></p>
                            <div className='qclass'>4. Как долго занимает обработка платежа?</div>
                            <div>Обычно обработка платежа занимает несколько минут. Однако, в некоторых случаях, обработка может занять до нескольких рабочих дней. Однако мы сразу после оплаты высылаем вам кватинцию об оплате, которая является документом подтверждющим факт оплаты.</div>
                            <p></p>
                            <hr />
                            <div>Мы надеемся, что эти ответы помогут вам в использовании нашего сервиса. Если у вас есть какие-либо дополнительные вопросы, пожалуйста, свяжитесь с нами, и мы будем рады помочь вам.</div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Faq;
