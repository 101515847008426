import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import Finephoto from '../Images/finephoto.png';

export const Secondblock = () => {

    return (
        <>
            <Card className='card-info-secondindex'>
                <Container>
                    <Row className='row-custom'>
                        <Col className='leftindextexttmaps'>
                            <Card.Body>
                                <Card.Title><h2 className='h2class'>Фотографии и место нарушения</h2></Card.Title>
                                <p><span className='discounttips'>Найдем для вас фотографии с камеры, которая зафиксировала нарушение и покажем место на карте</span></p><br /><br />
                                <p><span className='discounttips'>*при наличии этих данных в государственных базах</span></p>
                            </Card.Body>
                        </Col>

                        <Col className='rightindextextmaps d-none d-lg-block'>
                            <img
                                className='photoindex-img'
                                src={Finephoto}
                                alt="Фото нарушений"
                            />
                        </Col>

                    </Row>
                </Container>
            </Card>


        </>
    )
}
export default Secondblock

