import React from "react";

export const Regionerrors = () => {

    return (
        <>
          <div className="suggest-tips">Заполните регион</div>  
        </>
    )
}
export default Regionerrors