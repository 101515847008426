import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Logo from '../Images/shtrafy_sq_logo.svg';

export const Thirdblock = () => {

    return (
        <>
            <Card className='card-info-thirdblock'>
                <Container>
                    <Row className='row-custom'>
                        <Col className='leftindextextthird'>
                            <Card.Body>
                                <Card.Title><h2 className='h2classthird'>Найдем все штрафы</h2></Card.Title>
                                <p><span className='discounttipsthird'>Проверка в официальных государственных источниках</span></p>
                                <p><span className='discounttipsthird'>Если штраф найдется - мы покажем его с максимальным количеством доступной информации и вы сможете оплатить его буквально за минуту</span></p>
                            </Card.Body>
                        </Col>
                        <Col className="centerindextextthird col-5 suggest-field d-none d-lg-block" >
                        {/* <span className='centerthird'>ГИБДД</span><br/>
                        <span className='centerthird'>ФССП</span><br/>
                        <span className='centerthird'>АМПП</span><br/>
                        <span className='centerthird'>МАДИ</span><br/>
                        <span className='centerthird'>ГИС ГМП</span> */}
                        </Col >
                       


                        
                        <Col className='rightindextext col-5 suggest-field d-none d-lg-block'>
                            <Card.Body>
                            <img
                                className='logo-img'
                                src={Logo}
                                alt="Госномер авто"
                            />
                            </Card.Body>
                        </Col>
                    </Row>
                </Container>
            </Card>


        </>
    )
}
export default Thirdblock

