import React from "react";
import sts from '../Images/sts.svg';

export const Ststips = () => {

    return (
        <>
          


            <div className='righttips'>
                <div>
                    <h5 >Номер свидетельства о регистрации транспортного средства (СТС)</h5>
                    <img
                        className='sts-img'
                        src={sts}
                        alt="Свидетельство о регистрации транспортного средства"
                    />
                </div>
            </div>
            <div className='righttips '>
                <p>Розовая ламинированная карточка</p>
            </div>
            <div className='righttips '>
                <p>Внизу указан номер из 10 знаков</p>
            </div>
            
        </>

    )

}
export default Ststips