import React from "react";
import { Col, Card } from "react-bootstrap";

export const Secondblock = () => {

    return (
        <>
            <Col>
                <Card className='card-info-index'>
                    <Card.Body>
                        <Card.Title><h2 className='h2class'>Как это работает</h2></Card.Title>
                        <hr />
                        <div className='qclass'>Наш сервис позволяет в реальном времени проверить наличие штрафов ГИБДД по номеру СТС и оплатить их онлайн</div><br/>
                        <div className='qclass'>1. Укажите автомобильный номер и номер свидетельства о регистрации транспортного средства (СТС)</div>
                        <div className='aclass'>Сервис запросит штрафы ГИБДД в режиме реального времени по номеру СТС в официальных государстенных базах ГИБДД, ГИС ГМП и от службы судебных приставов</div>
                        <hr />
                        
                        <div className='qclass'>2. Укажите ваш адрес электронной почты</div>
                        <div className='aclass'>На этот адрес мы вышлем вам квитанцию об оплате, а также оперативно уведомим вас о появлении новых штрафов</div>
                        <hr />
                        <div className='qclass'>3. Для поиска штрафов нажмите «Проверить штрафы»</div>
                        <div className='aclass'>Если штрафы найдутся - мы предоставим актуальные данные в удобном виде.</div>

                    </Card.Body>

                </Card>
            </Col>
        </>
    )
}
export default Secondblock

